export const packageSizesInCm = {
  small: {
    length: 15,
    width: 13,
    height: 2,
  },
  medium: {
    length: 23,
    width: 18,
    height: 4,
  },
  large: {
    length: 35,
    width: 25,
    height: 10,
  },
  xlarge: {
    length: 40,
    width: 35,
    height: 12,
  },
};

export const longDistanceShippingCountries = [
  "USA",
  "Canada",
  "Australia",
  "NewZealand",
  "Spain",
];

export const isLongDistanceShipping = (country) => {
  if (longDistanceShippingCountries.includes(country)) {
    return true;
  }
  return false;
};

export const phoneNumberNotRequired = ["Norge"].concat(
  longDistanceShippingCountries
);

export const calculatePackageSize = (items) => {
  let containsXlargeItems = false;
  let containsLargeItems = false;
  let containsMediumItems = false;
  items.forEach((item) => {
    if (item.packageSize === "xlarge") {
      containsXlargeItems = true;
    } else if (item.packageSize === "large") {
      containsLargeItems = true;
    } else if (item.packageSize === "medium") {
      containsMediumItems = true;
    }
  });
  if (containsXlargeItems) {
    return packageSizesInCm.xlarge;
  } else if (containsLargeItems) {
    return packageSizesInCm.large;
  } else if (containsMediumItems) {
    return packageSizesInCm.medium;
  }
  return packageSizesInCm.small;
};

export const calculatePackageWeight = (items) => {
  let weight = 0.02; //base weight
  items.forEach((item) => {
    weight += item.weightInKilos;
  });
  return weight;
};

export const splitAddress = (address) => {
  if (address.replaceAll(",", "").match(/^\D+/) && address.match(/\d+/)) {
    return {
      street: address.replaceAll(",", "").match(/^\D+/)[0].trim(),
      number: address.match(/\d+/)[0].trim(),
      letter: address.match(/\d+(.*)/)[1].trim(),
    };
  }
  return {
    street: address.match(/\D+/) ? address.match(/\D+/)[0].trim() : address,
    number: address.match(/[0-9]+/) ? address.match(/[0-9]+/)[0].trim() : "",
    letter: "",
  };
};

export const createPackageDescription = (items) => {
  let packageContainsPatch = false;
  let packageContainsNeckGaiter = false;
  let packageContainsTshirt = false;
  let packageContainsHat = false;
  let packageContainsGloves = false;
  items.forEach((item) => {
    if (item.description.toLowerCase().includes("patch")) {
      packageContainsPatch = true;
    } else if (item.name.toLowerCase().includes("buff")) {
      packageContainsNeckGaiter = true;
    } else if (item.name.toLowerCase().includes("shirt")) {
      packageContainsTshirt = true;
    } else if (item.name.toLowerCase().includes("hat")) {
      packageContainsHat = true;
    } else if (item.name.toLowerCase().includes("MoG")) {
      packageContainsGloves = true;
    }
  });
  const description = `Sticker${packageContainsPatch ? "/patch" : ""}${
    packageContainsNeckGaiter ? "/buff" : ""
  }${packageContainsTshirt ? "/tshirt" : ""}${
    packageContainsHat ? "/hat" : ""
  }${packageContainsGloves ? "/gloves" : ""}`;
  return description;
};
