export const FREE_SHIPPING_NORWAY = 1000;
export const FREE_SHIPPING_OTHER = 10000;
export const SHIPPING_COST_MAILBOX = 80;
export const SHIPPING_COST_CUSTOM_STORE = 50;
export const SHIPPING_COST_STORE = 130;
export const SHIPPING_COST_DENMARK_SWEDEN = 125;
export const SHIPPING_COST_LONG_DISTANCE = 120;
export const SHIPPING_COST_LONG_DISTANCE_UPS = 410;
export const OPRA_DISCOUNT_PERCENTAGE = 0.1;

export const norwegian = [
  {
    id: "STRIKE_WARNING",
    translation:
      "Merk at pågående streik kan påvirke leveringstid. Vi anbefaler å bruke Rett i postkassen så lenge streiken pågår.",
  },
  { id: "DONATION_LABEL", translation: "Donasjon" },
  { id: "CHECKOUT_DONATION_HEADER", translation: "Valgfri donasjon til" },
  {
    id: "CHECKOUT_DONATION_EXPLAINER",
    translation: "Donasjonen går uavkortet til VAU i månedlige overføringer.",
  },
  {
    id: "CHECKOUT_DONATION_CONTEST",
    translation: "Doner og vinn plakett.",
  },
  {
    id: "DONATION_GREETING",
    translation: " Takk for din donasjon til Veteran Aid Ukraine.",
  },
  { id: "FRONTPAGE_HERO_HEADER_PATCH", translation: "IR-PATCHER" },
  { id: "FRONTPAGE_HERO_HEADER_BUFF", translation: "MESH-BUFFER" },
  { id: "FRONTPAGE_HERO_HEADER_CUSTOM", translation: "CUSTOM PATCHER" },
  {
    id: "FRONTPAGE_HERO_BUTTON_TEXT_PATCH",
    translation: "Skaff din patch i dag",
  },
  {
    id: "FRONTPAGE_HERO_BUTTON_TEXT_BUFF",
    translation: "Skaff din buff i dag",
  },
  {
    id: "FRONTPAGE_HERO_BUTTON_TEXT_CUSTOM",
    translation: "Bestill her",
  },
  { id: "HEADER_BACK", translation: "Tilbake" },
  { id: "HEADER_ALL_PRODUCTS", translation: "Se alle produkter" },
  { id: "FRONTPAGE_BIG_PATCHES_HEADER", translation: "Big Flag patcher" },
  { id: "FRONTPAGE_SMALL_PATCHES_HEADER", translation: "Small Flag patcher" },
  { id: "FRONTPAGE_PATCHES_HEADER", translation: "Patcher" },
  {
    id: "CUSTOM_PATCHES_TITLE",
    translation: "Skreddersydde patcher",
  },
  {
    id: "FRONTPAGE_CUSTOM_PATCHES_HEADER",
    translation: "Vi lager skreddersydde patcher av alle typer",
  },
  {
    id: "FRONTPAGE_CUSTOM_PATCHES_BUTTON",
    translation: "Ta kontakt her",
  },
  { id: "FRONTPAGE_TSHIRTS_HEADER", translation: "Bekledning" },
  { id: "FRONTPAGE_GADGETS_HEADER", translation: "Buffer" },
  {
    id: "FRONTPAGE_RANKPATCHES_HEADER",
    translation: "Distinksjoner, callsigns, div.",
  },
  { id: "FRONTPAGE_ADHESIVES_HEADER", translation: "Selvheftende" },
  { id: "FRONTPAGE_SEARCH_PLACEHOLDER", translation: "SØK" },
  { id: "FRONTPAGE_SEARCH_NO_MATCHES", translation: "Ingen treff 🤷‍♂️" },
  { id: "CURRENCY_NORWEGIAN", translation: "kr" },
  { id: "FRONTPAGE_ADD_TO_CART", translation: "Legg i handlesekk" },
  { id: "FRONTPAGE_MORE_INFO", translation: "Mer info" },
  { id: "FRONTPAGE_ADD_CHOOSE_SIZE", translation: "Velg str." },
  { id: "FRONTPAGE_ADD_CHOOSE_TYPE", translation: "Velg type" },
  { id: "FRONTPAGE_ADD_CHOOSE", translation: "Velg" },
  { id: "FRONTPAGE_OUT_OF_STOCK", translation: "Sorry, tomt på lager" },
  {
    id: "FRONTPAGE_SPECIFICATIONS_HEADER",
    translation: "Spesifikasjoner",
  },
  { id: "FRONTPAGE_SPECIFICATIONS_SEAM", translation: "Søm" },
  { id: "FRONTPAGE_SPECIFICATIONS_FABRIC", translation: "Nylon" },
  { id: "FRONTPAGE_SPECIFICATIONS_REFLEX", translation: "IR-refleks" },
  { id: "FRONTPAGE_SPECIFICATIONS_VELCRO", translation: "PVC-borrelås" },
  {
    id: "FRONTPAGE_SPECIFICATIONS_BUFF_MESH",
    translation: "Fleksibelt meshstoff",
  },
  {
    id: "FRONTPAGE_SPECIFICATIONS_BUFF_SEAM",
    translation: "Søm for grep og holdbarhet",
  },
  { id: "SWICTH_LANGUAGE_ENGLISH", translation: "Switch to English version" },
  { id: "SWICTH_LANGUAGE_NORWEGIAN", translation: "Bytt til norsk versjon" },
  { id: "SWICTH_LANGUAGE_SWEDISH", translation: "Byt till svenska" },
  { id: "SWICTH_LANGUAGE_DANISH", translation: "Skift til dansk" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN1", translation: "Intropris" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN2", translation: "Nyhet" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN3", translation: "Ny pris" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN4", translation: "Få igjen" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN5", translation: "Salg" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN6", translation: "Sniktitt" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN7", translation: "Begrenset" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN8", translation: "Blackout" },
  { id: "CART_TITLE", translation: "Handlesekk" },
  { id: "CART_TO_CHECKOUT", translation: "Til kassen" },
  { id: "CART_SUBTOTAL_AMOUNT", translation: "Sum:" },
  { id: "SHIPPING_AMOUNT", translation: "Frakt:" },
  { id: "SHIPPING_PRICE_UPDATED", translation: "Pris oppdatert" },
  {
    id: "SHIPPING_FREE_AMOUNT",
    translation: `Gratis frakt over ${FREE_SHIPPING_NORWAY} kr`,
  },
  { id: "SHIPPING_FREE", translation: "Gratis frakt!" },
  { id: "FRONTPAGE_FIELD_TESTED", translation: "Testet i felt" },
  { id: "FRONTPAGE_IR_REFLEXIVE", translation: "IR-refleksive patcher" },
  {
    id: "FRONTPAGE_SHIPPING",
    translation: `Gratis frakt over ${FREE_SHIPPING_NORWAY} kr`,
  },
  { id: "FRONTPAGE_MAILBOX_DIRECT", translation: "Rett i postkassen" },
  { id: "CHECKOUT_SUMMARY_HEADER", translation: "Oppsummering" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE", translation: "Rabattkode" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE_HEADER", translation: "Rabattkode" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE_BUTTON", translation: "Sjekk" },
  {
    id: "CHECKOUT_FORM_DISCOUNT_CODE_VALID",
    translation: "Rabattkode aktivert",
  },
  {
    id: "CHECKOUT_FORM_DISCOUNT_CODE_INVALID",
    translation: "Rabattkoden er ikke gyldig",
  },
  {
    id: "FRONTPAGE_SHIPPING_DISCLAIMER",
    translation: `Gratis frakt over ${FREE_SHIPPING_NORWAY} kr til Fastlands-Norge.`,
  },
  { id: "FOOTER_CONTACT", translation: "Kontakt" },
  { id: "WEBSITE_DOMAIN", translation: "no" },
  { id: "INSTAGRAM_LINK", translation: "3charlie er på Instagram" },
  {
    id: "NOTIFICATION_INSTAGRAM_LINK",
    translation: "Få oppdateringer på Instagram",
  },
  {
    id: "FOOTER_COOKIES_DISCLAIMER",
    translation: "Denne nettsiden bruker informasjonskapsler.",
  },
  {
    id: "FOOTER_ORG_NUMBER",
    translation: "Org.nr:",
  },
  { id: "PURCHASE_AGREEMENT", translation: "Kjøpsbetingelser" },
  { id: "CHECKOUT_HEADER", translation: "Kassen" },
  { id: "CHECKOUT_CURRENCY_HEADER", translation: "Valutaomregning:" },
  { id: "CHECKOUT_SHIPPING_AMOUNT", translation: "Frakt" },
  { id: "CHECKOUT_DISCOUNT", translation: "Rabatt" },
  {
    id: "CHECKOUT_SHIPPING_INTERNATIONAL_DISCLAIMER",
    translation: "(Bare Norge)",
  },
  { id: "TOTAL_AMOUNT", translation: "Total" },
  {
    id: "CHECKOUT_FORM_INSTRUCTIONS",
    translation: "Fyll inn detaljene dine under.",
  },
  { id: "CHECKOUT_FORM_NAME", translation: "Navn" },
  { id: "CHECKOUT_FORM_EMAIL", translation: "E-post" },
  { id: "CHECKOUT_FORM_FIRSTNAME_PLACEHOLDER", translation: "Fornavn" },
  { id: "CHECKOUT_FORM_LASTNAME_PLACEHOLDER", translation: "Etternavn" },
  { id: "CHECKOUT_FORM_EMAIL_PLACEHOLDER", translation: "E-postadresse" },
  { id: "CHECKOUT_FORM_ADDRESS", translation: "Adresse" },
  { id: "CHECKOUT_FORM_COUNTRY", translation: "Land" },
  { id: "CHECKOUT_FORM_PHONE_PLACEHOLDER", translation: "Telefonnummer" },
  { id: "CHECKOUT_FORM_COMMENT_PLACEHOLDER", translation: "Kommentar" },
  {
    id: "CHECKOUT_FORM_COMMENT_PLACEHOLDER_CALLSIGNS",
    translation: "Callsign/Kommentar",
  },
  {
    id: "CHECKOUT_FORM_PHONE",
    translation: "Tlf.nr. for pakkesporing & kommentar (valgfritt)",
  },
  {
    id: "CHECKOUT_FORM_PHONE_AMERICA",
    translation: "Telefonnummer og kommentar (valgfritt)",
  },
  {
    id: "CHECKOUT_FORM_ADDRESS_DISCLAIMER",
    translation:
      "ⓘ Sendes direkte til mottakers postkasse. Oppgi alternativt leiradresse.",
  },
  { id: "CHECKOUT_FORM_ADDRESS_PLACEHOLDER", translation: "Adresse" },
  { id: "CHECKOUT_FORM_POSTALCODE_PLACEHOLDER", translation: "Postnummer" },
  { id: "CHECKOUT_FORM_CITY_PLACEHOLDER", translation: "Poststed" },
  { id: "CHECKOUT_FORM_STATE_PLACEHOLDER", translation: "Stat" },
  { id: "CHECKOUT_FORM_COUNTRY_PLACEHOLDER", translation: "Land" },
  { id: "CHECKOUT_FORM_COUNTRY_NORWAY", translation: "Norge" },
  { id: "CHECKOUT_FORM_COUNTRY_SELECT", translation: "Velg land" },
  { id: "CHECKOUT_FORM_COUNTRY_UKRAINE", translation: "Ukraina" },
  { id: "CHECKOUT_FORM_COUNTRY_SWEDEN", translation: "Sverige" },
  { id: "CHECKOUT_FORM_COUNTRY_DENMARK", translation: "Danmark" },
  { id: "CHECKOUT_FORM_COUNTRY_FINLAND", translation: "Finland" },
  { id: "CHECKOUT_FORM_COUNTRY_UK", translation: "Storbritannia" },
  { id: "CHECKOUT_FORM_COUNTRY_GERMANY", translation: "Tyskland" },
  { id: "CHECKOUT_FORM_COUNTRY_BELGIUM", translation: "Belgia" },
  { id: "CHECKOUT_FORM_COUNTRY_HOLLAND", translation: "Nederland" },
  { id: "CHECKOUT_FORM_COUNTRY_ITALY", translation: "Italia" },
  { id: "CHECKOUT_FORM_COUNTRY_FRANCE", translation: "Frankrike" },
  { id: "CHECKOUT_FORM_COUNTRY_SPAIN", translation: "Spania" },
  { id: "CHECKOUT_FORM_COUNTRY_PORTUGAL", translation: "Portugal" },
  { id: "CHECKOUT_FORM_COUNTRY_POLAND", translation: "Polen" },
  { id: "CHECKOUT_FORM_COUNTRY_CANADA", translation: "Canada" },
  { id: "CHECKOUT_FORM_COUNTRY_USA", translation: "USA" },
  { id: "CHECKOUT_FORM_COUNTRY_AUSTRALIA", translation: "Australia" },
  { id: "CHECKOUT_FORM_COUNTRY_NEW_ZEALAND", translation: "New Zealand" },
  { id: "CHECKOUT_FORM_COUNTRY_LATVIA", translation: "Latvia" },
  { id: "CHECKOUT_FORM_COUNTRY_LITHUANIA", translation: "Litauen" },
  { id: "CHECKOUT_FORM_COUNTRY_ESTONIA", translation: "Estland" },
  { id: "CHECKOUT_FORM_COUNTRY_SLOVAKIA", translation: "Slovakia" },
  { id: "CHECKOUT_FORM_COUNTRY_CZECH", translation: "Tsjekkia" },
  { id: "CHECKOUT_FORM_COUNTRY_IRELAND", translation: "Irland" },
  { id: "CHECKOUT_FORM_REMEMBER_ME", translation: "Husk meg" },
  { id: "CHECKOUT_FORM_CARD", translation: "Betalingskort" },
  { id: "CHECKOUT_FORM_ACCEPT", translation: "Jeg godtar" },
  { id: "CHECKOUT_FORM_SEND_ABROAD", translation: "Send til utlandet" },
  { id: "CHECKOUT_FORM_AGREEMENT", translation: "kjøpsbetingelsene" },
  { id: "CHECKOUT_FORM_PROCEED", translation: "Gå videre" },
  { id: "CHECKOUT_FORM_SHIPPING_METHOD", translation: "Velg leveringsmetode" },
  { id: "CHECKOUT_FORM_PAYMENT_METHOD", translation: "Velg betalingsmetode" },
  { id: "CHECKOUT_SHIPPING_MAILBOX_BTN", translation: "Rett i postkassen" },
  { id: "CHECKOUT_SHIPPING_STORE_BTN", translation: "Hentested" },
  {
    id: "CHECKOUT_SHIPPING_CALCULATION_MESSAGE",
    translation: "Frakt beregnes under",
  },
  {
    id: "CHECKOUT_SHIPPING_PLACEHOLDER_MESSAGE",
    translation: "Oppgi postnummer",
  },
  { id: "CHECKOUT_PAY_CARD_BTN", translation: "Kort" },
  { id: "CHECKOUT_PAY_VIPPS_BTN", translation: "Vipps" },
  { id: "CHECKOUT_PAY_GIFTCARD_BTN", translation: "Løs inn gavekort" },
  {
    id: "CHECKOUT_PAY_GIFTCARD_TOOLTIP",
    translation: "Gavekortet må dekke hele kjøpssummen inkl. frakt",
  },
  { id: "CHECKOUT_FORM_GIFTCARD_CODE", translation: "Kode fra gavekortet" },
  { id: "CHECKOUT_FORM_GIFTCARD_PLACEHOLDER", translation: "XXXX-XXXX-XXXX" },
  {
    id: "CHECKOUT_GIFTCARD_ERROR_ALREADY_USED",
    translation: "Gavekortet har allerede blitt brukt",
  },
  {
    id: "CHECKOUT_GIFTCARD_ERROR_INSUFFICIENT",
    translation: "Gavekortet dekker ikke kjøpssummen",
  },
  {
    id: "CHECKOUT_GIFTCARD_NOT_VALID",
    translation: "Gavekortet er dessverre ikke gyldig",
  },
  { id: "NOTIFY_ME_BUTTON", translation: "Varsle meg" },
  { id: "NOTIFY_ME_BUTTON_NOTIFIED", translation: "Du vil bli varslet" },
  { id: "NOTIFYME_FORM_HEADER_1", translation: "Varsle meg når " },
  { id: "NOTIFYME_FORM_HEADER_2", translation: " blir tilgjengelig" },
  {
    id: "NOTIFYME_GENERIC_ERROR",
    translation: "Sorry, det funket ikke. Prøv igjen senere.",
  },
  { id: "NOTIFYME_SEND_BUTTON", translation: "Lagre varsling" },
  { id: "PRODUCT_SIZE_LABEL", translation: "Str." },
  { id: "PRODUCT_TYPE_LABEL", translation: "Type:" },
  { id: "PRODUCT_SIZE_GUIDE", translation: "Størrelsesguide" },
  {
    id: "SIZE_UP_DISCLAIMER",
    translation: "I tvil? Gå en størrelse opp.",
  },
  { id: "MORE_INFO_HEADER", translation: "Dokumentasjon kreves" },
  {
    id: "MORE_INFO_TEXT",
    translation:
      "Dette produktet krever dokumentasjon på kvalifikasjon før bestillingen sendes. Vi ber om at det sendes en e-post til 1@3charlie.no, som bekrefter at du er eller har vært jeger. F.eks. e-post fra troppsjef i jegertropp eller lignende. Når denne er mottatt, sendes bestillingen i sin helhet til oppgitt adresse.",
  },
  {
    id: "MORE_INFO_TEXT_2",
    translation: "Takk for forståelsen.",
  },
  { id: "GALLERY_MORE_IMAGES_LINK", translation: "Flere bilder på Instagram" },
  {
    id: "CHECKOUT_FORM_GENERIC_ERROR",
    translation:
      "Betalingen ble gjennomført, men ordren ble ikke registrert. Vennligst ta kontakt på 1@3charlie.no",
  },
  {
    id: "CHECKOUT_FORM_PAYMENT_ERROR",
    translation: "Betalingen ble ikke gjennomført.",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_MISSING",
    translation: "⚠ Du må fylle ut alle feltene",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_CARD",
    translation: "⚠ Betalingskort er ikke riktig fylt ut",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_EMAIL",
    translation: "⚠ Det ser ikke ut som du har fylt ut en gyldig e-postadresse",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_PHONE",
    translation: "⚠ Du må fylle ut et telefonnummer",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_RESTRICTED_PRODUCTS",
    translation:
      "Handlesekken inneholder produkter som ikke kan sendes til valgt land.",
  },
  {
    id: "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING",
    translation:
      "Handlesekken inneholder begrensede produkter. Trykk her for å se over.",
  },
  {
    id: "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING_BUTTON",
    translation: "Trykk her for å se over.",
  },
  {
    id: "CHECKOUT_REMOVE_RESTRICTED_PRODUCTS_BUTTON",
    translation: "Fjern produktene",
  },
  {
    id: "CHECKOUT_RESTRICTED_PRODUCT_LABEL",
    translation: "Begrenset",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_GIFTCARD",
    translation: "⚠ Koden fra gavekortet er ikke i henhold",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_AGREEMENT",
    translation: "⚠ Du må godta kjøpsbetingelsene",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_PHONE",
    translation: "⚠ Det ser ikke ut som telefonnummeret er i henhold",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_POSTALCODE",
    translation: "⚠ Postnummeret er ikke gyldig",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_NO_PICKUP_POINT",
    translation: "⚠ Du må velge et sted å hente pakken",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_ERROR_PICKUP_POINT",
    translation:
      "Fant ingen hentesteder på dette postnummeret. Prøv et annet postnummer.",
  },
  {
    id: "CHECKOUT_EMPTY_CART",
    translation: "Du har ingenting i handlesekken.",
  },
  {
    id: "SHIPPING_TIME_MAILBOX",
    translation: "Du kan også oppgi adresse for postmottak på leir.",
  },
  { id: "SHIPPING_TIME_PREFIX", translation: "Leveringstid" },
  {
    id: "SHIPPING_TIME_PREFIX_LONG_DISTANCE_STANDARD",
    translation: "Uten sporing. Leveringstid",
  },
  {
    id: "SHIPPING_TIME_PREFIX_LONG_DISTANCE_UPS",
    translation: "Med sporing. Leveringstid",
  },
  { id: "BOOM", translation: "Boom" },
  { id: "SHIPPING_TIME_DAYS", translation: "virkedager." },
  { id: "ORDER_CONFIRMATION_HEADER", translation: "Kjøpet er gjennomført" },
  {
    id: "ORDER_VIPPS_ERROR",
    translation:
      "Beklager, betalingen kunne ikke gjennomføres og ordren ble ikke registrert.",
  },
  {
    id: "ORDER_VIPPS_MESSAGE",
    translation:
      "Takk for bestillingen. Du vil motta en bekreftelse per e-post når ordren er registrert.",
  },
  { id: "ORDER_CONFIRMATION_EMAIL", translation: "Bekreftelse er sendt til" },
  {
    id: "ORDER_CONFIRMATION_SPAM_DISCLAIMER",
    translation: "Sjekk spam-mappen din om du ikke finner ordrebekreftelsen.",
  },
  { id: "ORDER_CONFIRMATION_LINK", translation: "Gå til forsiden" },
  { id: "ORDER_CONFIRMATION_SUMMARY", translation: "Din bestilling" },
  {
    id: "ORDER_CONFIRMATION_GIFTCARD_REMAINING",
    translation: "Resterende på gavekortet:",
  },
  { id: "ORDER_CONFIRM_DIALOG_HEADER", translation: "Bekreft kjøpet ditt" },
  { id: "ORDER_CONFIRM_DIALOG_VAT", translation: "Herav mva" },
  {
    id: "ORDER_CONFIRM_DIALOG_NOK_CHARGE",
    translation: "Valutakurser er omtrentlige. Du vil bli belastet i NOK.",
  },
  { id: "ORDER_CONFIRM_DIALOG_BUY", translation: "Kjøp" },
  { id: "ORDER_CONFIRM_DIALOG_BUY_GIFTCARD", translation: "Kjøp med gavekort" },
  { id: "ORDER_CONFIRM_DIALOG_CANCEL", translation: "Avbryt" },
  { id: "GIFTCARD_PAGE_HEADER", translation: "Kjøp gavekort" },
  { id: "GIFTCARD_LINK", translation: "Gavekort" },
  {
    id: "GIFTCARD_REDEEM_INFO",
    translation:
      "Løse inn gavekort? Legg varer i handlesekken, gå til kassen og velg gavekort som betalingsmåte.",
  },
  { id: "CUSTOM_PATCHES_HEADER", translation: "Custom stuff" },
  {
    id: "ORDER_CONFIRM_DIALOG_PROCESSING",
    translation: "Ting tar tid ...",
  },
  {
    id: "ORDER_CONFIRM_DIALOG_PROCESSING_VIPPS",
    translation: "Betalingen kan bli fullført i et annet vindu.",
  },
  {
    id: "ORDER_CONFIRM_DIALOG_REDIRECT_VIPPS",
    translation: "Sender deg videre til Vipps",
  },
  {
    id: "CONFIRMATION_EMAIL_SUBJECT",
    translation: "Ordrebekreftelse",
  },
  {
    id: "TRACKING_EMAIL_SUBJECT",
    translation: "Spor bestillingen din",
  },
  {
    id: "TRACKING_EMAIL_BODY",
    translation: "Du kan spore bestillingen din via denne lenken: ",
  },
  {
    id: "TRACKING_EMAIL_CUSTOMS_FEE_DISCLAIMER",
    translation:
      "Vær oppmerksom på at du kan måtte betale et tollgebyr før bestillingen din kan leveres.",
  },
  {
    id: "TRACKING_EMAIL_THANKS",
    translation: "Takk igjen for bestillingen din.",
  },
  { id: "CONFIRMATION_EMAIL_HI", translation: "Hei" },
  { id: "CONFIRMATION_EMAIL_UNITS", translation: "stk." },
  { id: "CONFIRMATION_EMAIL_VAT", translation: "Herav mva" },
  { id: "CONFIRMATION_EMAIL_THANKS", translation: "Takk for bestillingen din" },
  {
    id: "CONFIRMATION_EMAIL_INFO",
    translation: "Bestillingen din sendes asap zulu.",
  },
  { id: "CONFIRMATION_EMAIL_AGREEMENT", translation: "Angrerettskjema" },
  { id: "CONFIRMATION_EMAIL_SIGNATURE", translation: "3charlie slutt" },
  { id: "COOKIE_CONSENT_BUTTON", translation: "Jeg godtar" },
  {
    id: "COOKIE_CONSENT_TEXT",
    translation:
      'Denne nettsiden bruker informasjonskapsler. Trykk på "Jeg godtar" for å akseptere.',
  },
  {
    id: "PURCHASE_AGREEMENT_CONFIRMATION_HEADER",
    translation: "Ordrebekreftelse",
  },
  {
    id: "PURCHASE_AGREEMENT_CONFIRMATION_TEXT",
    translation:
      "3charlie.no sender deg ordrebekreftelse per e-post. Ved betaling med Vipps vil du kunne få bekreftelsen når ordren er sendt. Husk å oppgi din korrekte e-postadresse. Har du ikke mottatt ordrebekreftelse, send en e-post til",
  },
  {
    id: "PURCHASE_AGREEMENT_PAYMENT_HEADER",
    translation: "Betaling",
  },
  {
    id: "PURCHASE_AGREEMENT_PAYMENT_TEXT",
    translation:
      "3charlie.no belaster kjøperen ved bestillingstidspunktet. Evt. bruk av valutakurser er omtrentlig og du vil bli belastet i NOK. Dersom det skulle vise seg å være et problem med bestillingen og den ikke kan oppfylles, vil 3charlie.no refundere det fulle beløpet tilbake til kortet som ble brukt ved bestilling.",
  },
  {
    id: "PURCHASE_AGREEMENT_SHIPPING_HEADER",
    translation: "Frakt og gebyr",
  },
  {
    id: "PURCHASE_AGREEMENT_SHIPPING_TEXT",
    translation: `3charlie.no sender ordren til deg med Posten Norge (rett i postkassen) eller Postnord AS (hentested). Det er gratis frakt ved bestillinger over ${FREE_SHIPPING_NORWAY} kr til adresser i Fastlands-Norge for sendinger til postkassen. Sjekk at adressen du har oppgitt er riktig før du bekrefter ordren.`,
  },
  {
    id: "PURCHASE_AGREEMENT_DELIVERY_HEADER",
    translation: "Leveringstid",
  },
  {
    id: "PURCHASE_AGREEMENT_DELIVERY_TEXT",
    translation:
      "Varen(e) sendes til kjøper så fort som mulig. Normal leveringstid er 6-7 virkedager til postkassen (Posten), og 4-5 virkedager til hentested (Postnord).",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_HEADER",
    translation: "Angrerett og returfrakt",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_1",
    translation:
      "Kjøperen må gi selger melding om bruk av angreretten innen 14 dager fra fristen begynner å løpe. Angrefristen begynner å løpe fra dagen etter varen(e) er mottatt. I fristen inkluderes alle kalenderdager. Dersom fristen ender på en lørdag, helligdag eller høytidsdag forlenges fristen til nærmeste virkedag. Send en e-post til",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_2",
    translation:
      "for å angre din ordre. Angrefristen anses overholdt dersom melding er sendt før utløpet av fristen. I tilfelle ordren allerede er sendt før kjøper benytter seg av angreretten, fyll ut",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_LINK",
    translation: "angrerettskjema",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_3",
    translation: ", og send varen(e) og skjemaet i retur til:",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_4",
    translation: "Kjøper betaler returfrakten.",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_5",
    translation:
      "Kjøper kan prøve eller teste varen(e) på en forsvarlig måte for å fastslå varens art, egenskaper og funksjon, uten at angreretten faller bort. Dersom prøving eller test av varen(e) går utover hva som er forsvarlig og nødvendig, kan kjøperen bli ansvarlig for eventuell redusert verdi på varen(e).",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_6",
    translation:
      "Selgeren er forpliktet til å tilbakebetale kjøpesummen til kjøperen uten unødig opphold, og senest 14 dager fra selgeren fikk melding om kjøperens beslutning om å benytte angreretten. Selger har rett til å holde tilbake betalingen til varen(e) er mottatt fra kjøperen, eller til kjøper har lagt frem dokumentasjon for at varen(e) er sendt  tilbake.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_HEADER",
    translation: "Reklamasjon",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_1",
    translation:
      "Hvis det foreligger en mangel ved varen(e) må kjøper innen rimelig tid etter at den ble oppdaget eller burde ha blitt oppdaget, gi selger melding om at kjøper vil påberope seg mangelen. Kjøper har alltid reklamert tidsnok dersom det skjer innen 2 mnd. fra mangelen ble oppdaget eller burde blitt oppdaget.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_2",
    translation:
      "Dersom varen(e) har en mangel og dette ikke skyldes kjøperen eller forhold på kjøperens side, kan kjøperen velge mellom retting og omlevering, kreve prisavslag eller kreve avtalen hevet.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_3",
    translation: "Reklamasjon til selgeren skal skje skriftlig per e-post til",
  },
  {
    id: "PURCHASE_AGREEMENT_DISPUTES_HEADER",
    translation: "Konfliktløsing",
  },
  {
    id: "PURCHASE_AGREEMENT_DISPUTES_TEXT",
    translation:
      "Klager rettes til selger innen rimelig tid. Partene skal forsøke å løse eventuelle tvister i minnelighet. Dersom dette ikke lykkes, kan kjøperen ta kontakt med Forbrukerrådet for mekling.",
  },
  {
    id: "PURCHASE_AGREEMENT_PRIVACY_HEADER",
    translation: "Personopplysninger",
  },
  {
    id: "PURCHASE_AGREEMENT_PRIVACY_TEXT",
    translation:
      "Ditt navn, din adresse, e-postadresse og evt. telefonnummer lagres hos 3charlie.no. Disse opplysningene innhentes utelukkende for at 3charlie.no skal kunne sende ordrebekreftelse og selve ordren til deg, alternativt for å kunne kontakte deg dersom det oppstår et problem med ordren din. Personopplysninger deles ikke med andre parter. Dersom du ønsker innsyn i dine personopplysninger eller ønsker å få dine personopplysninger slettet fra 3charlie.no, ber vi deg ta kontakt på",
  },
  { id: "CLOSE", translation: "Lukk" },
  {
    id: "EXTRA_DELIVERY_TIME",
    translation: "🚛  Noe lenger leveringstid bør påregnes i juli 🏖",
  },
];

export const english = [
  {
    id: "STRIKE_WARNING",
    translation:
      "The ongoing strike may delay delivery times. We reccommend using the Mailbox option for the duration of the strike.",
  },
  { id: "DONATION_LABEL", translation: "Donation" },
  { id: "CHECKOUT_DONATION_HEADER", translation: "Optional donation to" },
  {
    id: "CHECKOUT_DONATION_EXPLAINER",
    translation: "The donation goes to VAU in full in monthly transfers.",
  },
  {
    id: "CHECKOUT_DONATION_CONTEST",
    translation: "Donate and win a plaque.",
  },
  {
    id: "DONATION_GREETING",
    translation: " Thanks for your donation to Veteran Aid Ukraine.",
  },
  { id: "FRONTPAGE_HERO_HEADER_PATCH", translation: "IR PATCHES" },
  { id: "FRONTPAGE_HERO_HEADER_BUFF", translation: "NECK GAITERS" },
  { id: "FRONTPAGE_HERO_HEADER_CUSTOM", translation: "CUSTOM PATCHES" },
  {
    id: "FRONTPAGE_HERO_BUTTON_TEXT_PATCH",
    translation: "Get your patch today",
  },
  {
    id: "FRONTPAGE_HERO_BUTTON_TEXT_BUFF",
    translation: "Get your neck gaiter today",
  },

  {
    id: "FRONTPAGE_HERO_BUTTON_TEXT_CUSTOM",
    translation: "Order here",
  },
  { id: "HEADER_BACK", translation: "Back" },
  { id: "HEADER_ALL_PRODUCTS", translation: "See all products" },
  { id: "FRONTPAGE_BIG_PATCHES_HEADER", translation: "Big Flag Patch" },
  { id: "FRONTPAGE_SMALL_PATCHES_HEADER", translation: "Small Flag Patch" },
  { id: "FRONTPAGE_PATCHES_HEADER", translation: "Patches" },
  {
    id: "CUSTOM_PATCHES_TITLE",
    translation: "Custom Patches",
  },
  {
    id: "FRONTPAGE_CUSTOM_PATCHES_HEADER",
    translation: "We make custom patches of all types",
  },
  {
    id: "FRONTPAGE_CUSTOM_PATCHES_BUTTON",
    translation: "Submit your request",
  },
  { id: "FRONTPAGE_TSHIRTS_HEADER", translation: "Apparel" },
  { id: "FRONTPAGE_GADGETS_HEADER", translation: "Neck Gaiters" },
  {
    id: "FRONTPAGE_RANKPATCHES_HEADER",
    translation: "Rank insignia, callsigns, misc.",
  },
  { id: "FRONTPAGE_ADHESIVES_HEADER", translation: "Adhesives" },
  { id: "FRONTPAGE_SEARCH_PLACEHOLDER", translation: "SEARCH" },
  { id: "FRONTPAGE_SEARCH_NO_MATCHES", translation: "No matches 🤷‍♂️" },
  { id: "CURRENCY_NORWEGIAN", translation: "NOK" },
  { id: "FRONTPAGE_ADD_TO_CART", translation: "Add to cart" },
  { id: "FRONTPAGE_MORE_INFO", translation: "More info" },
  { id: "FRONTPAGE_ADD_CHOOSE_SIZE", translation: "Select a size" },
  { id: "FRONTPAGE_ADD_CHOOSE_TYPE", translation: "Select a type" },
  { id: "FRONTPAGE_ADD_CHOOSE", translation: "Select" },
  { id: "FRONTPAGE_OUT_OF_STOCK", translation: "Sorry, out of stock" },
  {
    id: "FRONTPAGE_SPECIFICATIONS_HEADER",
    translation: "Specifications",
  },
  { id: "FRONTPAGE_SPECIFICATIONS_SEAM", translation: "Seam" },
  { id: "FRONTPAGE_SPECIFICATIONS_FABRIC", translation: "Nylon" },
  { id: "FRONTPAGE_SPECIFICATIONS_REFLEX", translation: "IR Reflex" },
  { id: "FRONTPAGE_SPECIFICATIONS_VELCRO", translation: "PVC Velcro" },
  {
    id: "FRONTPAGE_SPECIFICATIONS_BUFF_MESH",
    translation: "Flexible mesh fabric",
  },
  {
    id: "FRONTPAGE_SPECIFICATIONS_BUFF_SEAM",
    translation: "Seam for grip and durability",
  },
  { id: "SWICTH_LANGUAGE_ENGLISH", translation: "Switch to English version" },
  { id: "SWICTH_LANGUAGE_NORWEGIAN", translation: "Bytt til norsk versjon" },
  { id: "SWICTH_LANGUAGE_SWEDISH", translation: "Byt till svenska" },
  { id: "SWICTH_LANGUAGE_DANISH", translation: "Skift til dansk" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN1", translation: "Intro offer" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN2", translation: "New" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN3", translation: "New Price" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN4", translation: "Few left" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN5", translation: "Sale" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN6", translation: "Preview" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN7", translation: "Limited" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN8", translation: "Blackout" },
  { id: "CART_TITLE", translation: "Cart" },
  { id: "CART_TO_CHECKOUT", translation: "Checkout" },
  { id: "CART_SUBTOTAL_AMOUNT", translation: "Sum:" },
  { id: "SHIPPING_AMOUNT", translation: "Shipping:" },
  { id: "SHIPPING_PRICE_UPDATED", translation: "Price updated" },
  {
    id: "SHIPPING_FREE_AMOUNT",
    translation: `Free shipping to Norway over ${FREE_SHIPPING_NORWAY} NOK`,
  },
  { id: "SHIPPING_FREE", translation: "Free shipping to Norway!" },
  { id: "FRONTPAGE_FIELD_TESTED", translation: "Field tested" },
  { id: "FRONTPAGE_IR_REFLEXIVE", translation: "IR Reflective Patches" },
  {
    id: "FRONTPAGE_SHIPPING",
    translation: `Low international shipping fees`,
  },
  { id: "FRONTPAGE_MAILBOX_DIRECT", translation: "Mail box delivery" },
  {
    id: "FRONTPAGE_SHIPPING_DISCLAIMER",
    translation:
      "See checkout for international shipping fees and restrictions.",
  },
  { id: "FOOTER_CONTACT", translation: "Contact" },
  { id: "WEBSITE_DOMAIN", translation: "no" },
  { id: "INSTAGRAM_LINK", translation: "3charlie is on Instagram" },
  {
    id: "NOTIFICATION_INSTAGRAM_LINK",
    translation: "Get updates on Instagram",
  },
  {
    id: "FOOTER_COOKIES_DISCLAIMER",
    translation: "This website uses cookies.",
  },

  {
    id: "FOOTER_ORG_NUMBER",
    translation: "Org. no:",
  },
  { id: "PURCHASE_AGREEMENT", translation: "Purchase Agreement" },
  { id: "CHECKOUT_HEADER", translation: "Checkout" },
  { id: "CHECKOUT_CURRENCY_HEADER", translation: "Currency conversion:" },
  { id: "CHECKOUT_SUMMARY_HEADER", translation: "Summary" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE", translation: "Discount code" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE_HEADER", translation: "Discount code" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE_BUTTON", translation: "Check" },
  {
    id: "CHECKOUT_FORM_DISCOUNT_CODE_VALID",
    translation: "Discount code active",
  },
  {
    id: "CHECKOUT_FORM_DISCOUNT_CODE_INVALID",
    translation: "Discount code invalid",
  },
  { id: "CHECKOUT_SHIPPING_AMOUNT", translation: "Shipping" },
  { id: "CHECKOUT_DISCOUNT", translation: "Discount" },
  {
    id: "CHECKOUT_SHIPPING_INTERNATIONAL_DISCLAIMER",
    translation: "(only shipping to Norway, Sweden and Denmark)",
  },
  { id: "TOTAL_AMOUNT", translation: "Total" },
  {
    id: "CHECKOUT_FORM_INSTRUCTIONS",
    translation: "Fill out your details below.",
  },
  { id: "CHECKOUT_FORM_NAME", translation: "Name" },
  { id: "CHECKOUT_FORM_EMAIL", translation: "Email" },
  { id: "CHECKOUT_FORM_FIRSTNAME_PLACEHOLDER", translation: "First Name" },
  { id: "CHECKOUT_FORM_LASTNAME_PLACEHOLDER", translation: "Last Name" },
  { id: "CHECKOUT_FORM_EMAIL_PLACEHOLDER", translation: "Email Address" },
  { id: "CHECKOUT_FORM_ADDRESS", translation: "Shipping Address" },
  { id: "CHECKOUT_FORM_COUNTRY", translation: "Country" },
  {
    id: "CHECKOUT_FORM_PHONE_PLACEHOLDER",
    translation: "Phone number",
  },
  { id: "CHECKOUT_FORM_COMMENT_PLACEHOLDER", translation: "Comment" },
  {
    id: "CHECKOUT_FORM_COMMENT_PLACEHOLDER_CALLSIGNS",
    translation: "Callsign/Comment",
  },
  {
    id: "CHECKOUT_FORM_PHONE",
    translation: "Phone number for tracking and comment (optional)",
  },
  {
    id: "CHECKOUT_FORM_PHONE_AMERICA",
    translation: "Phone number & comment (optional)",
  },
  { id: "CHECKOUT_FORM_ADDRESS_PLACEHOLDER", translation: "Address" },
  { id: "CHECKOUT_FORM_POSTALCODE_PLACEHOLDER", translation: "Postal Code" },
  { id: "CHECKOUT_FORM_CITY_PLACEHOLDER", translation: "City" },
  { id: "CHECKOUT_FORM_STATE_PLACEHOLDER", translation: "State" },
  { id: "CHECKOUT_FORM_COUNTRY_PLACEHOLDER", translation: "Country" },
  { id: "CHECKOUT_FORM_COUNTRY_NORWAY", translation: "Norway" },
  { id: "CHECKOUT_FORM_COUNTRY_SELECT", translation: "Select country" },
  { id: "CHECKOUT_FORM_COUNTRY_UKRAINE", translation: "Ukraine" },
  { id: "CHECKOUT_FORM_COUNTRY_SWEDEN", translation: "Sweden" },
  { id: "CHECKOUT_FORM_COUNTRY_FINLAND", translation: "Finland" },
  { id: "CHECKOUT_FORM_COUNTRY_DENMARK", translation: "Denmark" },
  { id: "CHECKOUT_FORM_COUNTRY_UK", translation: "United Kingdom" },
  { id: "CHECKOUT_FORM_COUNTRY_GERMANY", translation: "Germany" },
  { id: "CHECKOUT_FORM_COUNTRY_BELGIUM", translation: "Belgium" },
  { id: "CHECKOUT_FORM_COUNTRY_HOLLAND", translation: "The Netherlands" },
  { id: "CHECKOUT_FORM_COUNTRY_ITALY", translation: "Italy" },
  { id: "CHECKOUT_FORM_COUNTRY_FRANCE", translation: "France" },
  { id: "CHECKOUT_FORM_COUNTRY_SPAIN", translation: "Spain" },
  { id: "CHECKOUT_FORM_COUNTRY_PORTUGAL", translation: "Portugal" },
  { id: "CHECKOUT_FORM_COUNTRY_POLAND", translation: "Poland" },
  { id: "CHECKOUT_FORM_COUNTRY_CANADA", translation: "Canada" },
  { id: "CHECKOUT_FORM_COUNTRY_USA", translation: "USA" },
  { id: "CHECKOUT_FORM_COUNTRY_AUSTRALIA", translation: "Australia" },
  { id: "CHECKOUT_FORM_COUNTRY_NEW_ZEALAND", translation: "New Zealand" },
  { id: "CHECKOUT_FORM_COUNTRY_LATVIA", translation: "Latvia" },
  { id: "CHECKOUT_FORM_COUNTRY_LITHUANIA", translation: "Lithuania" },
  { id: "CHECKOUT_FORM_COUNTRY_ESTONIA", translation: "Estonia" },
  { id: "CHECKOUT_FORM_COUNTRY_SLOVAKIA", translation: "Slovakia" },
  { id: "CHECKOUT_FORM_COUNTRY_CZECH", translation: "Czechia" },
  { id: "CHECKOUT_FORM_COUNTRY_IRELAND", translation: "Ireland" },
  { id: "CHECKOUT_FORM_REMEMBER_ME", translation: "Remember me" },
  { id: "CHECKOUT_FORM_CARD", translation: "Card Details" },
  { id: "CHECKOUT_FORM_ACCEPT", translation: "I accept" },
  { id: "CHECKOUT_FORM_SEND_ABROAD", translation: "Send abroad" },
  { id: "CHECKOUT_FORM_AGREEMENT", translation: "the purchase agreement" },
  { id: "CHECKOUT_FORM_PROCEED", translation: "Proceed" },
  {
    id: "CHECKOUT_FORM_SHIPPING_METHOD",
    translation: "Select Shipping Option",
  },
  { id: "CHECKOUT_FORM_PAYMENT_METHOD", translation: "Select Payment Method" },
  { id: "CHECKOUT_SHIPPING_MAILBOX_BTN", translation: "Mailbox" },
  { id: "CHECKOUT_SHIPPING_STORE_BTN", translation: "Pick up point" },
  {
    id: "CHECKOUT_SHIPPING_CALCULATION_MESSAGE",
    translation: "Shipping is calculated below",
  },
  {
    id: "CHECKOUT_SHIPPING_PLACEHOLDER_MESSAGE",
    translation: "Submit postal code",
  },
  { id: "CHECKOUT_PAY_CARD_BTN", translation: "Card" },
  { id: "CHECKOUT_PAY_VIPPS_BTN", translation: "Vipps" },
  { id: "CHECKOUT_PAY_GIFTCARD_BTN", translation: "Redeem Gift Card" },
  {
    id: "CHECKOUT_PAY_GIFTCARD_TOOLTIP",
    translation: "The Gift Card must cover the total amount including shipping",
  },
  { id: "CHECKOUT_FORM_GIFTCARD_CODE", translation: "Kode fra gavekortet" },
  { id: "CHECKOUT_FORM_GIFTCARD_CODE", translation: "Gift Card Code" },
  { id: "CHECKOUT_FORM_GIFTCARD_PLACEHOLDER", translation: "XXXX-XXXX-XXXX" },
  {
    id: "CHECKOUT_GIFTCARD_ERROR_ALREADY_USED",
    translation: "The gift card has already been fully redeemed",
  },
  {
    id: "CHECKOUT_GIFTCARD_ERROR_INSUFFICIENT",
    translation: "The gift card does not have enough balance",
  },
  {
    id: "CHECKOUT_GIFTCARD_NOT_VALID",
    translation: "The gift card is no longer valid",
  },
  { id: "NOTIFY_ME_BUTTON", translation: "Notify me" },
  { id: "NOTIFY_ME_BUTTON_NOTIFIED", translation: "You'll be notified" },
  { id: "NOTIFYME_FORM_HEADER_1", translation: "Notify me when " },
  { id: "NOTIFYME_FORM_HEADER_2", translation: " becomes available" },
  {
    id: "NOTIFYME_GENERIC_ERROR",
    translation: "Sorry, that didn't work. Please try again later.",
  },
  { id: "NOTIFYME_SEND_BUTTON", translation: "Notify me" },
  { id: "PRODUCT_SIZE_LABEL", translation: "Size" },
  { id: "PRODUCT_TYPE_LABEL", translation: "Type:" },
  { id: "PRODUCT_SIZE_GUIDE", translation: "Size guide" },
  { id: "SIZE_UP_DISCLAIMER", translation: "In doubt? Go up a size." },
  { id: "MORE_INFO_HEADER", translation: "Confirmation required" },
  {
    id: "MORE_INFO_TEXT",
    translation:
      "This product requires documentation of eligibility before the order is shipped. We ask that an e-mail be sent to 1@3charlie.no, from an @mil.no e-mail address, which confirms the qualification. Once this has been received, the complete order is shipped to the given address.",
  },
  {
    id: "MORE_INFO_TEXT_2",
    translation: "Thank you for your understanding.",
  },
  { id: "GALLERY_MORE_IMAGES_LINK", translation: "More photos on Instagram" },
  {
    id: "CHECKOUT_FORM_GENERIC_ERROR",
    translation:
      "Your payment was processed, but there was a problem with registering your order. Please contact 1@3charlie.no",
  },
  {
    id: "CHECKOUT_FORM_PAYMENT_ERROR",
    translation: "Your payment was not processed.",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_MISSING",
    translation: "⚠ Required fields are missing",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_CARD",
    translation: "⚠ Your card details are not valid",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_EMAIL",
    translation: "⚠ Your email does not appear to be valid",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_PHONE",
    translation: "⚠ You will need to fill out your phone number",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_RESTRICTED_PRODUCTS",
    translation:
      "The cart contains items that are restricted from international shipping.",
  },
  {
    id: "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING",
    translation: "The cart contains restricted items.",
  },
  {
    id: "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING_BUTTON",
    translation: "Press here to review.",
  },
  {
    id: "CHECKOUT_REMOVE_RESTRICTED_PRODUCTS_BUTTON",
    translation: "Remove restricted products",
  },
  {
    id: "CHECKOUT_RESTRICTED_PRODUCT_LABEL",
    translation: "Restricted",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_GIFTCARD",
    translation: "⚠ The gift card code is not valid",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_AGREEMENT",
    translation: "⚠ Please accept the Purchase Agreement",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_PHONE",
    translation: "⚠ Please check that you have entered a valid phone number",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_POSTALCODE",
    translation: "⚠ The postal code is not valid",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_NO_PICKUP_POINT",
    translation: "⚠ You have to select a store for pick up",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_ERROR_PICKUP_POINT",
    translation: "Didn't find any pickup points for this postal code.",
  },
  {
    id: "CHECKOUT_EMPTY_CART",
    translation: "Your cart is empty.",
  },
  {
    id: "SHIPPING_TIME_MAILBOX",
    translation: "",
  },
  { id: "SHIPPING_TIME_PREFIX", translation: "Delivery time" },
  {
    id: "SHIPPING_TIME_PREFIX_LONG_DISTANCE_STANDARD",
    translation: "No tracking. Delivery time",
  },
  {
    id: "SHIPPING_TIME_PREFIX_LONG_DISTANCE_UPS",
    translation: "Tracked. Delivery time",
  },
  { id: "BOOM", translation: "Boom" },
  { id: "SHIPPING_TIME_DAYS", translation: "business days." },
  { id: "ORDER_CONFIRMATION_HEADER", translation: "Order Completed" },
  {
    id: "ORDER_VIPPS_ERROR",
    translation:
      "Sorry, your payment could not be processed and the order was not registered.",
  },
  {
    id: "ORDER_VIPPS_MESSAGE",
    translation:
      "Thanks for your order. You will receive an email confirmation when your order is registered.",
  },
  {
    id: "ORDER_CONFIRMATION_EMAIL",
    translation: "An order confirmation has been sent to",
  },
  {
    id: "ORDER_CONFIRMATION_SPAM_DISCLAIMER",
    translation:
      "Check your spam folder if you can't find your order confirmation email.",
  },
  { id: "ORDER_CONFIRMATION_LINK", translation: "Go to front page" },
  { id: "ORDER_CONFIRMATION_SUMMARY", translation: "You ordered" },
  {
    id: "ORDER_CONFIRMATION_GIFTCARD_REMAINING",
    translation: "Remaining on your gift card:",
  },
  { id: "ORDER_CONFIRM_DIALOG_HEADER", translation: "Confirm your order" },
  { id: "ORDER_CONFIRM_DIALOG_VAT", translation: "Included VAT" },
  {
    id: "ORDER_CONFIRM_DIALOG_NOK_CHARGE",
    translation: "Exchange rates are approximate. You will be charged in NOK.",
  },
  { id: "ORDER_CONFIRM_DIALOG_BUY", translation: "Buy" },
  {
    id: "ORDER_CONFIRM_DIALOG_BUY_GIFTCARD",
    translation: "Buy with gift card",
  },
  { id: "ORDER_CONFIRM_DIALOG_CANCEL", translation: "Cancel" },
  { id: "GIFTCARD_PAGE_HEADER", translation: "Buy a Gift Card" },
  { id: "GIFTCARD_LINK", translation: "Gift Cards" },
  {
    id: "GIFTCARD_REDEEM_INFO",
    translation:
      "Redeem a gift card? Add items to the cart, go to checkout and select redeem gift card as payment method.",
  },
  { id: "CUSTOM_PATCHES_HEADER", translation: "Custom stuff" },
  {
    id: "ORDER_CONFIRM_DIALOG_PROCESSING",
    translation: "Slow is smooth, smooth is fast.",
  },
  {
    id: "ORDER_CONFIRM_DIALOG_PROCESSING_VIPPS",
    translation: "The payment may complete in another window.",
  },
  {
    id: "ORDER_CONFIRM_DIALOG_REDIRECT_VIPPS",
    translation: "Sending you over to Vipps",
  },
  {
    id: "CONFIRMATION_EMAIL_SUBJECT",
    translation: "Order confirmation",
  },
  {
    id: "TRACKING_EMAIL_SUBJECT",
    translation: "Track your order",
  },
  {
    id: "TRACKING_EMAIL_BODY",
    translation: "You can track your order via this link: ",
  },
  {
    id: "TRACKING_EMAIL_CUSTOMS_FEE_DISCLAIMER",
    translation:
      "Please observe that you may have to pay a customs fee before your order can be delivered.",
  },
  {
    id: "TRACKING_EMAIL_THANKS",
    translation: "Thanks again for your order.",
  },
  { id: "CONFIRMATION_EMAIL_HI", translation: "Hi" },
  { id: "CONFIRMATION_EMAIL_UNITS", translation: "pcs." },
  { id: "CONFIRMATION_EMAIL_VAT", translation: "Hereof VAT" },
  { id: "CONFIRMATION_EMAIL_THANKS", translation: "Thanks for your order" },
  {
    id: "CONFIRMATION_EMAIL_INFO",
    translation: "Your order will be shipped ASAP.",
  },
  {
    id: "CONFIRMATION_EMAIL_AGREEMENT",
    translation: "Cancellation Form",
  },
  { id: "CONFIRMATION_EMAIL_SIGNATURE", translation: "3charlie out" },
  { id: "COOKIE_CONSENT_BUTTON", translation: "I accept" },
  {
    id: "COOKIE_CONSENT_TEXT",
    translation: 'This website uses cookies. Press "I accept" to consent.',
  },
  {
    id: "PURCHASE_AGREEMENT_CONFIRMATION_HEADER",
    translation: "Order confirmation",
  },
  {
    id: "PURCHASE_AGREEMENT_CONFIRMATION_TEXT",
    translation:
      "3charlie.no will send you an order confirmation by email as soon as your order has been processed. When paying with Vipps, this confirmation may be sent when the order is shipped. Be sure to enter your correct email address. If you do not receive an order confirmation by email, please send a message to",
  },
  {
    id: "PURCHASE_AGREEMENT_PAYMENT_HEADER",
    translation: "Payment",
  },
  {
    id: "PURCHASE_AGREEMENT_PAYMENT_TEXT",
    translation:
      "3charlie.no will charge the buyer at the time of order. Exchange rates are approximate. You will be charged in NOK. If there is a problem with the order and it can not be fulfilled, the seller will refund the full amount to the payment card that was used when ordering.",
  },
  {
    id: "PURCHASE_AGREEMENT_SHIPPING_HEADER",
    translation: "Shipping and fees",
  },
  {
    id: "PURCHASE_AGREEMENT_SHIPPING_TEXT",
    translation: `3charlie.no will ship the order to you with Posten Norge (mailbox) or Postnord AS (pickup point). You will receive the order in your mailbox (Posten) or at a pickup point (Postnord). Shipping to your mailbox is free for purchases over ${FREE_SHIPPING_NORWAY} NOK to addresses in mainland Norway. Before confirming your order, make sure the address you entered is correct.`,
  },
  {
    id: "PURCHASE_AGREEMENT_DELIVERY_HEADER",
    translation: "Delivery",
  },
  {
    id: "PURCHASE_AGREEMENT_DELIVERY_TEXT",
    translation:
      "Your order will be shipped to your address as soon as possible. Normal delivery time is 6-7 business days to your mailbox (Posten) and 4-5 days to pickup point (Postnord).",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_HEADER",
    translation: "Cancellations and returns",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_1",
    translation:
      "The buyer must notify the seller of the use of the right of cancellation within 14 days from the deadline begins to run. The cancellation period begins to run from the day after the item(s) is received. The deadline includes all calendar days. If the deadline ends on a Saturday or public holiday, the deadline is extended to the nearest working day. Send a message to",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_2",
    translation:
      "to cancel your order. The cancellation deadline is considered compliant if the message is sent before the expiry of the deadline. In case the order has already been shipped before the buyer exercises the right of cancellation, fill in",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_LINK",
    translation: "the cancellation form",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_3",
    translation:
      ", and return the item(s) as well as the cancellation form to:",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_4",
    translation: "The buyer will have to pay for the return shipping.",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_5",
    translation:
      "The buyer can try or test the items(s) in a responsible manner to determine the its nature, properties and function, without the right of cancellation lapsing. If testing of the item goes beyond what is justifiable and necessary, the buyer may be responsible for any reduced value of the item.",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_6",
    translation:
      "The seller is obliged to repay the purchase amount to the buyer without undue delay, and no later than 14 days from the seller was notified of the buyer's decision to exercise the right of cancellation. The seller has the right to withhold payment until he has received the item(s) from the buyer, or until the buyer has submitted documentation that the item(s) have been returned.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_HEADER",
    translation: "Complaints",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_1",
    translation:
      "If there is a defect in the item(s), the buyer must within a reasonable time after it was discovered, or should have been discovered, notify the seller that the buyer will invoke the complaint. The buyer has always complained in due time if it happens within 2 months from the defect was discovered or should have been discovered.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_2",
    translation:
      "If the item(s) has a defect and this is not due to the buyer or conditions on the buyer's side, the buyer can choose between re-delivery, demand a price reduction or demand that the agreement be terminated.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_3",
    translation: "Complaints must be submitted by email to",
  },
  {
    id: "PURCHASE_AGREEMENT_DISPUTES_HEADER",
    translation: "Dispute resolution",
  },
  {
    id: "PURCHASE_AGREEMENT_DISPUTES_TEXT",
    translation:
      "Disputes must be addressed to the seller within a reasonable time. The parties shall endeavor to resolve any disputes amicably. If this does not succeed, the buyer can contact the Norwegian Consumer Council for mediation.",
  },
  {
    id: "PURCHASE_AGREEMENT_PRIVACY_HEADER",
    translation: "Privacy",
  },
  {
    id: "PURCHASE_AGREEMENT_PRIVACY_TEXT",
    translation:
      "Your name, address, e-mail and phone number (optional) are stored by 3charlie.no. This information is collected solely in order for 3charlie.no to send the order confirmation and the order itself to you, or alternatively to contact you if there is a problem with your order. Personal information is not shared with other parties. If you want access to your personal data or want your personal data to be deleted from 3charlie.no, please contact",
  },
  { id: "CLOSE", translation: "Close" },

  {
    id: "EXTRA_DELIVERY_TIME",
    translation: "🚛  Please note somewhat longer delivery times during July 🏖",
  },
];

export const swedish = [
  {
    id: "STRIKE_WARNING",
    translation:
      "The ongoing strike may delay delivery times. We reccommend using the Mailbox option for the duration of the strike.",
  },
  { id: "DONATION_LABEL", translation: "Donation" },
  { id: "CHECKOUT_DONATION_HEADER", translation: "Valfri donation till" },
  {
    id: "CHECKOUT_DONATION_EXPLAINER",
    translation: "Donationen går oavkortat till VAU i månatliga överföringar.",
  },
  {
    id: "CHECKOUT_DONATION_CONTEST",
    translation: "Donera och vinn en plakett.",
  },
  {
    id: "DONATION_GREETING",
    translation: " Tack för din donation till Veteran Aid Ukraine.",
  },
  { id: "FRONTPAGE_HERO_HEADER_PATCH", translation: "IR-PATCHAR" },
  { id: "FRONTPAGE_HERO_HEADER_BUFF", translation: "MESH-BUFFER" },
  { id: "FRONTPAGE_HERO_HEADER_CUSTOM", translation: "CUSTOM PATCHES" },
  { id: "FRONTPAGE_HERO_BUTTON_TEXT_PATCH", translation: "Få din patch i dag" },
  { id: "FRONTPAGE_HERO_BUTTON_TEXT_BUFF", translation: "Få din buff i dag" },
  {
    id: "FRONTPAGE_HERO_BUTTON_TEXT_CUSTOM",
    translation: "Bestil her",
  },
  { id: "HEADER_BACK", translation: "Tilbaka" },
  { id: "HEADER_ALL_PRODUCTS", translation: "Alla produkter" },
  { id: "FRONTPAGE_BIG_PATCHES_HEADER", translation: "Big Flag patchar" },
  { id: "FRONTPAGE_SMALL_PATCHES_HEADER", translation: "Small Flag patchar" },
  { id: "FRONTPAGE_PATCHES_HEADER", translation: "Patchar" },
  {
    id: "CUSTOM_PATCHES_TITLE",
    translation: "Custom patchar",
  },
  {
    id: "FRONTPAGE_CUSTOM_PATCHES_HEADER",
    translation: "Vi gör anpassade patchar av alla slag",
  },
  {
    id: "FRONTPAGE_CUSTOM_PATCHES_BUTTON",
    translation: "Hör av dig här",
  },
  { id: "FRONTPAGE_TSHIRTS_HEADER", translation: "Bekledning" },
  { id: "FRONTPAGE_GADGETS_HEADER", translation: "Buffar" },
  {
    id: "FRONTPAGE_RANKPATCHES_HEADER",
    translation: "Distinktioner, callsigns, div.",
  },
  { id: "FRONTPAGE_ADHESIVES_HEADER", translation: "Självhäftande" },
  { id: "FRONTPAGE_SEARCH_PLACEHOLDER", translation: "SÖK" },
  { id: "FRONTPAGE_SEARCH_NO_MATCHES", translation: "Inga resultat 🤷‍♂️" },
  { id: "CURRENCY_NORWEGIAN", translation: "NOK" },
  { id: "FRONTPAGE_ADD_TO_CART", translation: "Lägg i säcken" },
  { id: "FRONTPAGE_MORE_INFO", translation: "Mer info" },
  { id: "FRONTPAGE_ADD_CHOOSE_SIZE", translation: "Välj storlek" },
  { id: "FRONTPAGE_ADD_CHOOSE_TYPE", translation: "Välj typ" },
  { id: "FRONTPAGE_ADD_CHOOSE", translation: "Välj" },
  { id: "FRONTPAGE_OUT_OF_STOCK", translation: "Tyvärr, slut i lager" },
  {
    id: "FRONTPAGE_SPECIFICATIONS_HEADER",
    translation: "Specifikationer",
  },
  { id: "FRONTPAGE_SPECIFICATIONS_SEAM", translation: "Søm" },
  { id: "FRONTPAGE_SPECIFICATIONS_FABRIC", translation: "Nylon" },
  { id: "FRONTPAGE_SPECIFICATIONS_REFLEX", translation: "IR-reflex" },
  { id: "FRONTPAGE_SPECIFICATIONS_VELCRO", translation: "PVC-kardborre" },
  {
    id: "FRONTPAGE_SPECIFICATIONS_BUFF_MESH",
    translation: "Flexibelt meshtyg",
  },
  {
    id: "FRONTPAGE_SPECIFICATIONS_BUFF_SEAM",
    translation: "Söm för grepp och hållbarhet",
  },
  { id: "SWICTH_LANGUAGE_ENGLISH", translation: "Switch to English version" },
  { id: "SWICTH_LANGUAGE_NORWEGIAN", translation: "Bytt til norsk versjon" },
  { id: "SWICTH_LANGUAGE_SWEDISH", translation: "Byt till svenska" },
  { id: "SWICTH_LANGUAGE_DANISH", translation: "Skift til dansk" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN1", translation: "Intropris" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN2", translation: "Ny" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN3", translation: "Nytt pris" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN4", translation: "Få kvar" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN5", translation: "Rea" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN6", translation: "Smygtitt" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN7", translation: "Begränsad" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN8", translation: "Blackout" },
  { id: "CART_TITLE", translation: "Shoppingsäck" },
  { id: "CART_TO_CHECKOUT", translation: "Til kassan" },
  { id: "CART_SUBTOTAL_AMOUNT", translation: "Summa:" },
  { id: "SHIPPING_AMOUNT", translation: "Frakt:" },
  { id: "SHIPPING_PRICE_UPDATED", translation: "Pris uppdaterat" },
  {
    id: "SHIPPING_FREE_AMOUNT",
    translation: `Frakt till Sverige: ${SHIPPING_COST_DENMARK_SWEDEN} NOK`,
  },
  { id: "SHIPPING_FREE", translation: "Gratis frakt!" },
  { id: "FRONTPAGE_FIELD_TESTED", translation: "Testet i felt" },
  { id: "FRONTPAGE_IR_REFLEXIVE", translation: "IR-reflexive patcher" },
  {
    id: "FRONTPAGE_SHIPPING",
    translation: `Frakt till Sverige: ${SHIPPING_COST_DENMARK_SWEDEN} NOK`,
  },
  { id: "FRONTPAGE_MAILBOX_DIRECT", translation: "Direkt i brevlådan" },
  { id: "CHECKOUT_SUMMARY_HEADER", translation: "Sammanfattning" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE", translation: "Rabattkod" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE_HEADER", translation: "Rabattkod" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE_BUTTON", translation: "Kolla upp" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE_VALID", translation: "Rabattkod aktiv" },
  {
    id: "CHECKOUT_FORM_DISCOUNT_CODE_INVALID",
    translation: "Rabattkoden är inte giltig",
  },
  {
    id: "FRONTPAGE_SHIPPING_DISCLAIMER",
    translation: `Frakt till Sverige: ${SHIPPING_COST_DENMARK_SWEDEN} NOK`,
  },
  { id: "FOOTER_CONTACT", translation: "Kontakt" },
  { id: "WEBSITE_DOMAIN", translation: "se" },
  { id: "INSTAGRAM_LINK", translation: "3charlie är på Instagram" },
  {
    id: "NOTIFICATION_INSTAGRAM_LINK",
    translation: "Få uppdateringar på Instagram",
  },
  {
    id: "FOOTER_COOKIES_DISCLAIMER",
    translation: "Denna webbplatsen använder cookies",
  },
  {
    id: "FOOTER_ORG_NUMBER",
    translation: "Org.nr:",
  },
  { id: "PURCHASE_AGREEMENT", translation: "Köpvillkor" },
  { id: "CHECKOUT_HEADER", translation: "Kassan" },
  { id: "CHECKOUT_CURRENCY_HEADER", translation: "Valutaväxling:" },
  { id: "CHECKOUT_SHIPPING_AMOUNT", translation: "Frakt" },
  { id: "CHECKOUT_DISCOUNT", translation: "Rabatt" },
  {
    id: "CHECKOUT_SHIPPING_INTERNATIONAL_DISCLAIMER",
    translation: "(Bare Norge)",
  },
  { id: "TOTAL_AMOUNT", translation: "Total" },
  {
    id: "CHECKOUT_FORM_INSTRUCTIONS",
    translation: "Fyll i dina uppgifter nedan.",
  },
  { id: "CHECKOUT_FORM_NAME", translation: "Namn" },
  { id: "CHECKOUT_FORM_EMAIL", translation: "E-post" },
  { id: "CHECKOUT_FORM_FIRSTNAME_PLACEHOLDER", translation: "Förnamn" },
  { id: "CHECKOUT_FORM_LASTNAME_PLACEHOLDER", translation: "Efternamn" },
  { id: "CHECKOUT_FORM_EMAIL_PLACEHOLDER", translation: "E-postadress" },
  { id: "CHECKOUT_FORM_ADDRESS", translation: "Leveransadress" },
  { id: "CHECKOUT_FORM_COUNTRY", translation: "Land" },
  { id: "CHECKOUT_FORM_PHONE_PLACEHOLDER", translation: "Telefonnummer" },
  { id: "CHECKOUT_FORM_COMMENT_PLACEHOLDER", translation: "Kommentar" },
  {
    id: "CHECKOUT_FORM_COMMENT_PLACEHOLDER_CALLSIGNS",
    translation: "Callsign/Kommentar",
  },
  {
    id: "CHECKOUT_FORM_PHONE",
    translation: "Tlf.nr. för paketspårning & kommentar (valfritt)",
  },
  {
    id: "CHECKOUT_FORM_PHONE_AMERICA",
    translation: "Telefonnummer & kommentar (valfritt)",
  },
  { id: "CHECKOUT_FORM_ADDRESS_PLACEHOLDER", translation: "Adress" },
  { id: "CHECKOUT_FORM_POSTALCODE_PLACEHOLDER", translation: "Postnummer" },
  { id: "CHECKOUT_FORM_CITY_PLACEHOLDER", translation: "Stad" },
  { id: "CHECKOUT_FORM_STATE_PLACEHOLDER", translation: "Stat" },
  { id: "CHECKOUT_FORM_COUNTRY_PLACEHOLDER", translation: "Land" },
  { id: "CHECKOUT_FORM_COUNTRY_NORWAY", translation: "Norge" },
  { id: "CHECKOUT_FORM_COUNTRY_SELECT", translation: "Velg land" },
  { id: "CHECKOUT_FORM_COUNTRY_UKRAINE", translation: "Ukraina" },
  { id: "CHECKOUT_FORM_COUNTRY_SWEDEN", translation: "Sverige" },
  { id: "CHECKOUT_FORM_COUNTRY_FINLAND", translation: "Finland" },
  { id: "CHECKOUT_FORM_COUNTRY_DENMARK", translation: "Danmark" },
  { id: "CHECKOUT_FORM_COUNTRY_UK", translation: "Storbritannien" },
  { id: "CHECKOUT_FORM_COUNTRY_GERMANY", translation: "Tyskland" },
  { id: "CHECKOUT_FORM_COUNTRY_BELGIUM", translation: "Belgien" },
  { id: "CHECKOUT_FORM_COUNTRY_HOLLAND", translation: "Nederländerna" },
  { id: "CHECKOUT_FORM_COUNTRY_ITALY", translation: "Italien" },
  { id: "CHECKOUT_FORM_COUNTRY_FRANCE", translation: "Frankrike" },
  { id: "CHECKOUT_FORM_COUNTRY_SPAIN", translation: "Spanien" },
  { id: "CHECKOUT_FORM_COUNTRY_PORTUGAL", translation: "Portugal" },
  { id: "CHECKOUT_FORM_COUNTRY_POLAND", translation: "Polen" },
  { id: "CHECKOUT_FORM_COUNTRY_CANADA", translation: "Kanada" },
  { id: "CHECKOUT_FORM_COUNTRY_USA", translation: "USA" },
  { id: "CHECKOUT_FORM_COUNTRY_AUSTRALIA", translation: "Australien" },
  { id: "CHECKOUT_FORM_COUNTRY_NEW_ZEALAND", translation: "Nya Zeeland" },
  { id: "CHECKOUT_FORM_COUNTRY_LATVIA", translation: "Lettland" },
  { id: "CHECKOUT_FORM_COUNTRY_LITHUANIA", translation: "Litauen" },
  { id: "CHECKOUT_FORM_COUNTRY_ESTONIA", translation: "Estland" },
  { id: "CHECKOUT_FORM_COUNTRY_SLOVAKIA", translation: "Slovakien" },
  { id: "CHECKOUT_FORM_COUNTRY_CZECH", translation: "Tjeckien" },
  { id: "CHECKOUT_FORM_COUNTRY_IRELAND", translation: "Irland" },
  { id: "CHECKOUT_FORM_REMEMBER_ME", translation: "Kom ihåg mig" },
  { id: "CHECKOUT_FORM_CARD", translation: "Betalkort" },
  { id: "CHECKOUT_FORM_ACCEPT", translation: "Jag accepterar" },
  { id: "CHECKOUT_FORM_SEND_ABROAD", translation: "Send til utlandet" },
  { id: "CHECKOUT_FORM_AGREEMENT", translation: "köpvillkoren" },
  { id: "CHECKOUT_FORM_PROCEED", translation: "Gå vidare" },
  { id: "CHECKOUT_FORM_SHIPPING_METHOD", translation: "Välj fraktsätt" },
  { id: "CHECKOUT_FORM_PAYMENT_METHOD", translation: "Välj betalsätt" },
  { id: "CHECKOUT_SHIPPING_MAILBOX_BTN", translation: "Brevlåda" },
  { id: "CHECKOUT_SHIPPING_STORE_BTN", translation: "Leveranspunkt" },
  {
    id: "CHECKOUT_SHIPPING_CALCULATION_MESSAGE",
    translation: "Frakten beräknas nedan",
  },
  {
    id: "CHECKOUT_SHIPPING_PLACEHOLDER_MESSAGE",
    translation: "Ange postnummer",
  },
  { id: "CHECKOUT_PAY_CARD_BTN", translation: "Kort" },
  { id: "CHECKOUT_PAY_VIPPS_BTN", translation: "Vipps" },
  { id: "CHECKOUT_PAY_GIFTCARD_BTN", translation: "Løs inn gavekort" },
  {
    id: "CHECKOUT_PAY_GIFTCARD_TOOLTIP",
    translation: "Gavekortet må dekke hele kjøpssummen inkl. frakt",
  },
  { id: "CHECKOUT_FORM_GIFTCARD_CODE", translation: "Kode fra gavekortet" },
  { id: "CHECKOUT_FORM_GIFTCARD_PLACEHOLDER", translation: "XXXX-XXXX-XXXX" },
  {
    id: "CHECKOUT_GIFTCARD_ERROR_ALREADY_USED",
    translation: "Gavekortet har allerede blitt brukt",
  },
  {
    id: "CHECKOUT_GIFTCARD_ERROR_INSUFFICIENT",
    translation: "Gavekortet dekker ikke kjøpssummen",
  },
  {
    id: "CHECKOUT_GIFTCARD_NOT_VALID",
    translation: "Gavekortet er dessverre ikke gyldig",
  },
  { id: "NOTIFY_ME_BUTTON", translation: "Meddela mig" },
  { id: "NOTIFY_ME_BUTTON_NOTIFIED", translation: "Du kommer att meddelas" },
  { id: "NOTIFYME_FORM_HEADER_1", translation: "Meddela mig när " },
  { id: "NOTIFYME_FORM_HEADER_2", translation: " blir tillgänglig" },
  {
    id: "NOTIFYME_GENERIC_ERROR",
    translation: "Tyvärr, det fungerade inte. Försök igen senare.",
  },
  { id: "NOTIFYME_SEND_BUTTON", translation: "Spara" },
  { id: "PRODUCT_SIZE_LABEL", translation: "Stl." },
  { id: "PRODUCT_TYPE_LABEL", translation: "Typ:" },
  { id: "PRODUCT_SIZE_GUIDE", translation: "Storleksguide" },
  {
    id: "SIZE_UP_DISCLAIMER",
    translation: "I tvivel? Gå upp en storlek.",
  },
  { id: "MORE_INFO_HEADER", translation: "Dokumentation krävs" },
  {
    id: "MORE_INFO_TEXT",
    translation:
      "Denna produkt kräver dokumentation om kvalificering innan beställningen skickas. Vi ber att ett e-postmeddelande skickas till 1@3charlie.no, från en @mil.no e-postadress, som bekräftar kvalificeringen. När detta har mottagits skickas beställningen till angiven adress.",
  },
  {
    id: "MORE_INFO_TEXT_2",
    translation: "Tack för att du förstår.",
  },
  { id: "GALLERY_MORE_IMAGES_LINK", translation: "Fler bilder på Instagram" },
  {
    id: "CHECKOUT_FORM_GENERIC_ERROR",
    translation:
      "Betalningen slutfördes, men ordern registrerades inte. Vänligen kontakta 1@3charlie.no",
  },
  {
    id: "CHECKOUT_FORM_PAYMENT_ERROR",
    translation: "Betalningen slutfördes inte.",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_MISSING",
    translation: "⚠ Du måste fylla i alla fält",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_CARD",
    translation: "⚠ Betalkortet är inte korrekt ifyllt",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_EMAIL",
    translation:
      "⚠ Det ser inte ut som om du har fyllt i en giltig e-postadress",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_PHONE",
    translation: "⚠ Du måste fylla i ditt telefonnummer",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_RESTRICTED_PRODUCTS",
    translation:
      "Kurven indeholder produkter, der ikke kan sendes til det valgte land.",
  },
  {
    id: "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING",
    translation: "Kurven indeholder begrænsede produkter.",
  },
  {
    id: "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING_BUTTON",
    translation: "Tryk her for at se over.",
  },
  {
    id: "CHECKOUT_REMOVE_RESTRICTED_PRODUCTS_BUTTON",
    translation: "Fjern begrænsede produkter",
  },
  {
    id: "CHECKOUT_RESTRICTED_PRODUCT_LABEL  ",
    translation: "Begrænset",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_GIFTCARD",
    translation: "⚠ Koden fra gavekortet er ikke i henhold",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_AGREEMENT",
    translation: "⚠ Du måste acceptera köpvillkoren",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_PHONE",
    translation: "⚠ Kontrollera ditt telefonnummer",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_POSTALCODE",
    translation: "⚠ Postnummer är inte giltigt",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_NO_PICKUP_POINT",
    translation: "⚠ Du måste välja en butik att hämta i",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_ERROR_PICKUP_POINT",
    translation: "Inga upphämtningsställen hittades vid detta postnummer.",
  },
  {
    id: "CHECKOUT_EMPTY_CART",
    translation: "Du har inget i din säck.",
  },
  { id: "SHIPPING_TIME_MAILBOX", translation: "" },
  { id: "SHIPPING_TIME_PREFIX", translation: "Leveranstid" },
  {
    id: "SHIPPING_TIME_PREFIX_LONG_DISTANCE_STANDARD",
    translation: "Utan spårning. Leveranstid",
  },
  {
    id: "SHIPPING_TIME_PREFIX_LONG_DISTANCE_UPS",
    translation: "Med spårning. Leveranstid",
  },
  { id: "BOOM", translation: "Boom" },
  { id: "SHIPPING_TIME_DAYS", translation: "arbejdsdage." },
  { id: "ORDER_CONFIRMATION_HEADER", translation: "Ditt köp har slutförts" },
  {
    id: "ORDER_VIPPS_ERROR",
    translation:
      "Beklager, betalingen kunne ikke gjennomføres og ordren ble ikke registrert.",
  },
  {
    id: "ORDER_VIPPS_MESSAGE",
    translation:
      "Takk for bestillingen. Du vil motta en bekreftelse per e-post når ordren er registrert.",
  },
  { id: "ORDER_CONFIRMATION_EMAIL", translation: "Bekräftelse skickad till" },
  {
    id: "ORDER_CONFIRMATION_SPAM_DISCLAIMER",
    translation:
      "Kolla din skräppostmapp om du inte hittar din orderbekräftelse.",
  },
  { id: "ORDER_CONFIRMATION_LINK", translation: "Till framsidan" },
  { id: "ORDER_CONFIRMATION_SUMMARY", translation: "Din beställning" },
  {
    id: "ORDER_CONFIRMATION_GIFTCARD_REMAINING",
    translation: "Resterende på gavekortet:",
  },
  { id: "ORDER_CONFIRM_DIALOG_HEADER", translation: "Bekräfta ditt köp" },
  { id: "ORDER_CONFIRM_DIALOG_VAT", translation: "Varav MOMS" },
  {
    id: "ORDER_CONFIRM_DIALOG_NOK_CHARGE",
    translation: "Växelkurserna är ungefärliga. Du kommer att debiteras i NOK.",
  },
  { id: "ORDER_CONFIRM_DIALOG_BUY", translation: "Köp" },
  { id: "ORDER_CONFIRM_DIALOG_BUY_GIFTCARD", translation: "Kjøp med gavekort" },
  { id: "ORDER_CONFIRM_DIALOG_CANCEL", translation: "Annullera" },
  { id: "GIFTCARD_PAGE_HEADER", translation: "Köp presentkort" },
  { id: "GIFTCARD_LINK", translation: "Presentkort" },
  {
    id: "GIFTCARD_REDEEM_INFO",
    translation:
      "Løse inn gavekort? Legg varer i handlesekken, gå til kassen og velg gavekort som betalingsmåte.",
  },
  { id: "CUSTOM_PATCHES_HEADER", translation: "Custom stuff" },
  {
    id: "ORDER_CONFIRM_DIALOG_PROCESSING",
    translation: "Jobbar på det",
  },
  {
    id: "ORDER_CONFIRM_DIALOG_PROCESSING_VIPPS",
    translation: "Betalingen kan fullföras i et annat vindu.",
  },
  {
    id: "ORDER_CONFIRM_DIALOG_REDIRECT_VIPPS",
    translation: "Sender deg videre til Vipps",
  },
  {
    id: "CONFIRMATION_EMAIL_SUBJECT",
    translation: "Orderbekräftelse",
  },
  {
    id: "TRACKING_EMAIL_SUBJECT",
    translation: "Spåra din beställning",
  },
  {
    id: "TRACKING_EMAIL_BODY",
    translation: "Du kan spåra din beställning via denna länk: ",
  },
  {
    id: "TRACKING_EMAIL_CUSTOMS_FEE_DISCLAIMER",
    translation:
      "Observera att du kan behöva betala en tullavgift innan din beställning kan levereras.",
  },
  {
    id: "TRACKING_EMAIL_THANKS",
    translation: "Tack igen för beställningen.",
  },
  { id: "CONFIRMATION_EMAIL_HI", translation: "Hej" },
  { id: "CONFIRMATION_EMAIL_UNITS", translation: "st." },
  { id: "CONFIRMATION_EMAIL_VAT", translation: "Varav MOMS" },
  { id: "CONFIRMATION_EMAIL_THANKS", translation: "Tack för din beställning" },
  {
    id: "CONFIRMATION_EMAIL_INFO",
    translation: "Din beställning kommer att skickas asap zulu.",
  },
  { id: "CONFIRMATION_EMAIL_AGREEMENT", translation: "Ångerrätt formulär" },
  { id: "CONFIRMATION_EMAIL_SIGNATURE", translation: "3charlie slut" },
  { id: "COOKIE_CONSENT_BUTTON", translation: "Jag accepterar" },
  {
    id: "COOKIE_CONSENT_TEXT",
    translation:
      'Denna webbplatsen använder cookies. Tryck på "Jag accepterar" för att acceptera.',
  },
  {
    id: "PURCHASE_AGREEMENT_CONFIRMATION_HEADER",
    translation: "Orderbekräftelse",
  },
  {
    id: "PURCHASE_AGREEMENT_CONFIRMATION_TEXT",
    translation:
      "3charlie skickar beställningsbekräftelse via e-post. När du betalar med Vipps kommer du att kunna få bekräftelsen när beställningen har skickats. Kom ihåg att ange din korrekta e-postadress. Om du inte har fått en orderbekräftelse, skicka ett mail till",
  },
  {
    id: "PURCHASE_AGREEMENT_PAYMENT_HEADER",
    translation: "Betalning",
  },
  {
    id: "PURCHASE_AGREEMENT_PAYMENT_TEXT",
    translation:
      "3charlie debiterar köparen vid beställningstillfället. Växelkurserna är ungefärliga. Du kommer att debiteras i NOK. Om det visar sig vara ett problem med beställningen och det inte kan fullgöras, kommer 3charlie att återbetala hela beloppet till det kort som användes vid beställningen.",
  },
  {
    id: "PURCHASE_AGREEMENT_SHIPPING_HEADER",
    translation: "Frakt och leverans",
  },
  {
    id: "PURCHASE_AGREEMENT_SHIPPING_TEXT",
    translation: `3charlie skickar beställningen till dig med Posten Norge. Du kommer att få varorna i din brevlåda. Det finns gratis frakt över ${FREE_SHIPPING_OTHER} NOK till adresser i Sverige. Kontrollera att adressen du angav är korrekt innan du bekräftar beställningen.`,
  },
  {
    id: "PURCHASE_AGREEMENT_DELIVERY_HEADER",
    translation: "Leveranstid",
  },
  {
    id: "PURCHASE_AGREEMENT_DELIVERY_TEXT",
    translation:
      "Varorna skickas till köparen så snart som möjligt. Normal leveranstid är 6-7 arbetsdagar.",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_HEADER",
    translation: "Ångerrätt och returfrakt",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_1",
    translation:
      "Köparen måste meddela säljaren om användningen av ångerrätten inom 14 dagar efter det att tidsfristen börjar löpa. Avbokningsperioden börjar löpa från och med dagen efter det att varan har mottagits. Tidsfristen inkluderar alla kalenderdagar. Om tidsfristen slutar på en lördag, helgdag eller helgdag förlängs tidsfristen till närmaste arbetsdag. Skicka ett mail till",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_2",
    translation:
      "för att ångra din beställning. Ångerrätten anses uppfylld om meddelandet skickas innan tidsfristen löper ut. Om beställningen redan har skickats innan köparen utövar ångerrätten, fyll i",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_LINK",
    translation: "ångerrätt formuläret",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_3",
    translation: ", och skicka varorna och formuläret i retur till: ",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_4",
    translation: "Köparen betalar returfrakten.",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_5",
    translation:
      "Köparen kan prova eller testa objektet på ett ansvarsfullt sätt för att bestämma artikelns egenskaper och funktion utan att ångerrätten upphör att gälla. Om testning eller testning av artikeln går utöver vad som är nödvändigt, kan köparen vara ansvarig för varje reducerat värde på artikeln.",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_6",
    translation:
      "Säljaren är skyldig att återbetala köpeskillingen till köparen utan onödigt dröjsmål, och senast 14 dagar från säljaren underrättades köparens beslut att utöva ångerrätten. Säljaren har rätt att hålla inne betalningen tills varan har mottagits från köparen, eller tills köparen har lämnat in dokumentation för att varan har returnerats.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_HEADER",
    translation: "Klagomål",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_1",
    translation:
      "Om det finns en defekt i varorna måste köparen inom rimlig tid efter det att den upptäcktes eller borde ha upptäckts meddela säljaren att köparen åberopar defekten. Köparen har alltid klagat i tid om det händer inom två månader. från defekten upptäcktes eller borde ha upptäckts.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_2",
    translation:
      "Om varan / varorna har en defekt och detta inte beror på köparen eller villkoren på köparens sida, kan köparen välja mellan korrigering och återleverans, kräva en prisreduktion eller kräva att avtalet sägs upp.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_3",
    translation: "Klagomål till säljaren måste göras skriftligt via mail till",
  },
  {
    id: "PURCHASE_AGREEMENT_DISPUTES_HEADER",
    translation: "Konfliktløsing",
  },
  {
    id: "PURCHASE_AGREEMENT_DISPUTES_TEXT",
    translation:
      "Klagomål riktas till säljaren inom rimlig tid. Parterna ska sträva efter att lösa eventuella tvister i minnet. Om detta inte lyckas kan köparen kontakta konsumentrådet för medling.",
  },
  {
    id: "PURCHASE_AGREEMENT_PRIVACY_HEADER",
    translation: "Personlig information",
  },
  {
    id: "PURCHASE_AGREEMENT_PRIVACY_TEXT",
    translation:
      "Ditt namn, adress, telefonnummer och e-postadress lagras på 3charlie. Denna information erhålls enbart för att 3charlie ska kunna skicka orderbekräftelse och själva beställningen till dig, alternativt för att kunna kontakta dig om ett problem uppstår med din beställning. Personlig information delas inte med andra parter. Om du vill ha tillgång till din personliga information eller vill ha din personliga information raderad från 3charlie, vänligen kontakta",
  },
  { id: "CLOSE", translation: "Stänga" },
  {
    id: "EXTRA_DELIVERY_TIME",
    translation: "🚛  Något längre leveranstid bör förväntas i juli 🏖",
  },
];

export const danish = [
  {
    id: "STRIKE_WARNING",
    translation:
      "The ongoing strike may delay delivery times. We reccommend using the Mailbox option for the duration of the strike.",
  },
  { id: "DONATION_LABEL", translation: "Donation" },
  { id: "CHECKOUT_DONATION_HEADER", translation: "Valgfri donation til" },
  {
    id: "CHECKOUT_DONATION_EXPLAINER",
    translation: "Donationen går fuldt ud til VAU i månedlige overførsler.",
  },
  {
    id: "CHECKOUT_DONATION_CONTEST",
    translation: "Doner og vind en plakette.",
  },
  {
    id: "DONATION_GREETING",
    translation: " Tak for din donation til Veteran Aid Ukraine.",
  },
  { id: "FRONTPAGE_HERO_HEADER_PATCH", translation: "IR-PATCHES" },
  { id: "FRONTPAGE_HERO_HEADER_BUFF", translation: "MESH-BUFFER" },
  { id: "FRONTPAGE_HERO_HEADER_CUSTOM", translation: "CUSTOM PATCHES" },
  { id: "FRONTPAGE_HERO_BUTTON_TEXT_PATCH", translation: "Få din patch i dag" },
  { id: "FRONTPAGE_HERO_BUTTON_TEXT_BUFF", translation: "Få din buff i dag" },
  {
    id: "FRONTPAGE_HERO_BUTTON_TEXT_CUSTOM",
    translation: "Beställ här",
  },
  { id: "HEADER_BACK", translation: "Tilbage" },
  { id: "HEADER_ALL_PRODUCTS", translation: "Alle produkterne" },
  { id: "FRONTPAGE_BIG_PATCHES_HEADER", translation: "Big Flag Patch" },
  { id: "FRONTPAGE_SMALL_PATCHES_HEADER", translation: "Small Flag Patch" },
  { id: "FRONTPAGE_PATCHES_HEADER", translation: "Patches" },
  {
    id: "CUSTOM_PATCHES_TITLE",
    translation: "Custom patches",
  },
  {
    id: "FRONTPAGE_CUSTOM_PATCHES_HEADER",
    translation: "Vi laver tilpassede patches af alle typer",
  },
  {
    id: "FRONTPAGE_CUSTOM_PATCHES_BUTTON",
    translation: "Tag kontakt her",
  },
  { id: "FRONTPAGE_TSHIRTS_HEADER", translation: "Bekledning" },
  { id: "FRONTPAGE_GADGETS_HEADER", translation: "Buffs" },
  {
    id: "FRONTPAGE_RANKPATCHES_HEADER",
    translation: "Distinktioner, callsigns, div.",
  },
  { id: "FRONTPAGE_ADHESIVES_HEADER", translation: "Selvklæbende" },
  { id: "FRONTPAGE_SEARCH_PLACEHOLDER", translation: "SØG" },
  { id: "FRONTPAGE_SEARCH_NO_MATCHES", translation: "Ingen resultater 🤷‍♂️" },
  { id: "CURRENCY_NORWEGIAN", translation: "NOK" },
  { id: "FRONTPAGE_ADD_TO_CART", translation: "Tilføj til kurv" },
  { id: "FRONTPAGE_MORE_INFO", translation: "Mere info" },
  { id: "FRONTPAGE_ADD_CHOOSE_SIZE", translation: "Vælg størrelse" },
  { id: "FRONTPAGE_ADD_CHOOSE_TYPE", translation: "Vælg type" },
  { id: "FRONTPAGE_ADD_CHOOSE", translation: "Vælg" },
  { id: "FRONTPAGE_OUT_OF_STOCK", translation: "Sorry, ikke på lager" },
  {
    id: "FRONTPAGE_SPECIFICATIONS_HEADER",
    translation: "Specifikationer",
  },
  { id: "FRONTPAGE_SPECIFICATIONS_SEAM", translation: "Søm" },
  { id: "FRONTPAGE_SPECIFICATIONS_FABRIC", translation: "Nylon" },
  { id: "FRONTPAGE_SPECIFICATIONS_REFLEX", translation: "IR refleks" },
  { id: "FRONTPAGE_SPECIFICATIONS_VELCRO", translation: "PVC Velcro" },
  {
    id: "FRONTPAGE_SPECIFICATIONS_BUFF_MESH",
    translation: "Fleksibelt mesh stof",
  },
  {
    id: "FRONTPAGE_SPECIFICATIONS_BUFF_SEAM",
    translation: "Søm til greb og holdbarhed",
  },
  { id: "SWICTH_LANGUAGE_ENGLISH", translation: "Switch to English version" },
  { id: "SWICTH_LANGUAGE_NORWEGIAN", translation: "Bytt til norsk versjon" },
  { id: "SWICTH_LANGUAGE_SWEDISH", translation: "Byt till svenska" },
  { id: "SWICTH_LANGUAGE_DANISH", translation: "Skift til dansk" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN1", translation: "Introduktion" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN2", translation: "Ny" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN3", translation: "Ny pris" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN4", translation: "Begrænset" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN5", translation: "Salg" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN6", translation: "Smugkig" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN7", translation: "Begrænset" },
  { id: "FRONTPAGE_PATCHES_CAMPAIGN8", translation: "Blackout" },
  { id: "CART_TITLE", translation: "Kurv" },
  { id: "CART_TO_CHECKOUT", translation: "Kasse" },
  { id: "CART_SUBTOTAL_AMOUNT", translation: "Sum:" },
  { id: "SHIPPING_AMOUNT", translation: "Fragt:" },
  { id: "SHIPPING_PRICE_UPDATED", translation: "Pris opdateret" },
  {
    id: "SHIPPING_FREE_AMOUNT",
    translation: `Fragt til Danmark: ${SHIPPING_COST_DENMARK_SWEDEN} NOK`,
  },
  { id: "SHIPPING_FREE", translation: "Gratis fragt!" },
  { id: "FRONTPAGE_FIELD_TESTED", translation: "Felt testet" },
  { id: "FRONTPAGE_IR_REFLEXIVE", translation: "IR-reflekterende patches" },
  {
    id: "FRONTPAGE_SHIPPING",
    translation: `Fragt til Danmark: ${SHIPPING_COST_DENMARK_SWEDEN} NOK`,
  },
  { id: "FRONTPAGE_MAILBOX_DIRECT", translation: "Lige i postkassen" },
  {
    id: "FRONTPAGE_SHIPPING_DISCLAIMER",
    translation: `Fragt til Danmark: ${SHIPPING_COST_DENMARK_SWEDEN} NOK`,
  },
  { id: "FOOTER_CONTACT", translation: "Kontakt" },
  { id: "WEBSITE_DOMAIN", translation: "dk" },
  { id: "INSTAGRAM_LINK", translation: "3charlie er på Instagram" },
  {
    id: "NOTIFICATION_INSTAGRAM_LINK",
    translation: "Få oppdateringer på Instagram",
  },
  {
    id: "FOOTER_COOKIES_DISCLAIMER",
    translation: "Dette websted bruger cookies.",
  },

  {
    id: "FOOTER_ORG_NUMBER",
    translation: "Org. nr:",
  },
  { id: "PURCHASE_AGREEMENT", translation: "Købsaftale" },
  { id: "CHECKOUT_HEADER", translation: "Kasse" },
  { id: "CHECKOUT_CURRENCY_HEADER", translation: "Valuta omregning:" },
  { id: "CHECKOUT_SUMMARY_HEADER", translation: "Resumé" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE", translation: "Rabattkode" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE_HEADER", translation: "Rabattkode" },
  { id: "CHECKOUT_FORM_DISCOUNT_CODE_BUTTON", translation: "Tjek" },
  {
    id: "CHECKOUT_FORM_DISCOUNT_CODE_VALID",
    translation: "Rabattkode aktiveret",
  },
  {
    id: "CHECKOUT_FORM_DISCOUNT_CODE_INVALID",
    translation: "Rabattkode ugyldig",
  },
  { id: "CHECKOUT_SHIPPING_AMOUNT", translation: "Fragt" },
  { id: "CHECKOUT_DISCOUNT", translation: "Rabatt" },
  {
    id: "CHECKOUT_SHIPPING_INTERNATIONAL_DISCLAIMER",
    translation: "(only shipping to Norway, Sweden and Denmark)",
  },
  { id: "TOTAL_AMOUNT", translation: "Total" },
  {
    id: "CHECKOUT_FORM_INSTRUCTIONS",
    translation: "Udfyld dine oplysninger nedenfor",
  },
  { id: "CHECKOUT_FORM_NAME", translation: "Navn" },
  { id: "CHECKOUT_FORM_EMAIL", translation: "E-mail" },
  { id: "CHECKOUT_FORM_FIRSTNAME_PLACEHOLDER", translation: "Fornavn" },
  { id: "CHECKOUT_FORM_LASTNAME_PLACEHOLDER", translation: "Efternavn" },
  { id: "CHECKOUT_FORM_EMAIL_PLACEHOLDER", translation: "E-mail adresse" },
  { id: "CHECKOUT_FORM_ADDRESS", translation: "Forsendelsesadresse" },
  { id: "CHECKOUT_FORM_COUNTRY", translation: "Land" },
  { id: "CHECKOUT_FORM_PHONE_PLACEHOLDER", translation: "Telefonnummer" },
  { id: "CHECKOUT_FORM_COMMENT_PLACEHOLDER", translation: "Kommentar" },
  {
    id: "CHECKOUT_FORM_COMMENT_PLACEHOLDER_CALLSIGNS",
    translation: "Callsign/Kommentar",
  },
  {
    id: "CHECKOUT_FORM_PHONE",
    translation: "Tlf.nr. for pakkesporing & kommentar (valgfrit)",
  },
  {
    id: "CHECKOUT_FORM_PHONE_AMERICA",
    translation: "Telefonnummer & kommentar (valgfrit)",
  },
  { id: "CHECKOUT_FORM_ADDRESS_PLACEHOLDER", translation: "Addresse" },
  { id: "CHECKOUT_FORM_POSTALCODE_PLACEHOLDER", translation: "Postnummer" },
  { id: "CHECKOUT_FORM_CITY_PLACEHOLDER", translation: "By" },
  { id: "CHECKOUT_FORM_STATE_PLACEHOLDER", translation: "Stat" },
  { id: "CHECKOUT_FORM_COUNTRY_PLACEHOLDER", translation: "Land" },
  { id: "CHECKOUT_FORM_COUNTRY_NORWAY", translation: "Norge" },
  { id: "CHECKOUT_FORM_COUNTRY_SELECT", translation: "Velg land" },
  { id: "CHECKOUT_FORM_COUNTRY_UKRAINE", translation: "Ukraine" },
  { id: "CHECKOUT_FORM_COUNTRY_SWEDEN", translation: "Sverige" },
  { id: "CHECKOUT_FORM_COUNTRY_FINLAND", translation: "Finland" },
  { id: "CHECKOUT_FORM_COUNTRY_DENMARK", translation: "Danmark" },
  { id: "CHECKOUT_FORM_COUNTRY_UK", translation: "Storbritanien" },
  { id: "CHECKOUT_FORM_COUNTRY_GERMANY", translation: "Tyskland" },
  { id: "CHECKOUT_FORM_COUNTRY_BELGIUM", translation: "Belgien" },
  { id: "CHECKOUT_FORM_COUNTRY_HOLLAND", translation: "Holland" },
  { id: "CHECKOUT_FORM_COUNTRY_ITALY", translation: "Italien" },
  { id: "CHECKOUT_FORM_COUNTRY_FRANCE", translation: "Frankrige" },
  { id: "CHECKOUT_FORM_COUNTRY_SPAIN", translation: "Spanien" },
  { id: "CHECKOUT_FORM_COUNTRY_PORTUGAL", translation: "Portugal" },
  { id: "CHECKOUT_FORM_COUNTRY_POLAND", translation: "Polen" },
  { id: "CHECKOUT_FORM_COUNTRY_CANADA", translation: "Canada" },
  { id: "CHECKOUT_FORM_COUNTRY_USA", translation: "USA" },
  { id: "CHECKOUT_FORM_COUNTRY_AUSTRALIA", translation: "Australien" },
  { id: "CHECKOUT_FORM_COUNTRY_NEW_ZEALAND", translation: "New Zealand" },
  { id: "CHECKOUT_FORM_COUNTRY_LATVIA", translation: "Letland" },
  { id: "CHECKOUT_FORM_COUNTRY_LITHUANIA", translation: "Litauen" },
  { id: "CHECKOUT_FORM_COUNTRY_ESTONIA", translation: "Estland" },
  { id: "CHECKOUT_FORM_COUNTRY_SLOVAKIA", translation: "Slovakiet" },
  { id: "CHECKOUT_FORM_COUNTRY_CZECH", translation: "Tjekkiet" },
  { id: "CHECKOUT_FORM_COUNTRY_IRELAND", translation: "Irland" },
  { id: "CHECKOUT_FORM_REMEMBER_ME", translation: "Remember me" },
  { id: "CHECKOUT_FORM_CARD", translation: "Kortoplysninger" },
  { id: "CHECKOUT_FORM_ACCEPT", translation: "Jeg accepterer" },
  { id: "CHECKOUT_FORM_SEND_ABROAD", translation: "Send til utlandet" },
  { id: "CHECKOUT_FORM_AGREEMENT", translation: "købsaftalen" },
  { id: "CHECKOUT_FORM_PROCEED", translation: "Fortsæt" },
  {
    id: "CHECKOUT_FORM_SHIPPING_METHOD",
    translation: "Vælg forsendelsesmetode",
  },
  { id: "CHECKOUT_FORM_PAYMENT_METHOD", translation: "Vælg betalingsmetode" },
  { id: "CHECKOUT_SHIPPING_MAILBOX_BTN", translation: "Postkasse" },
  { id: "CHECKOUT_SHIPPING_STORE_BTN", translation: "Afhentningssted" },
  {
    id: "CHECKOUT_SHIPPING_CALCULATION_MESSAGE",
    translation: "Fragt er beregnet nedenfor",
  },
  {
    id: "CHECKOUT_SHIPPING_PLACEHOLDER_MESSAGE",
    translation: "Indtast postnummer",
  },
  { id: "CHECKOUT_PAY_CARD_BTN", translation: "Card" },
  { id: "CHECKOUT_PAY_VIPPS_BTN", translation: "Vipps" },
  { id: "CHECKOUT_PAY_GIFTCARD_BTN", translation: "Redeem Gift Card" },
  {
    id: "CHECKOUT_PAY_GIFTCARD_TOOLTIP",
    translation: "The Gift Card must cover the total amount including shipping",
  },
  { id: "CHECKOUT_FORM_GIFTCARD_CODE", translation: "Kode fra gavekortet" },
  { id: "CHECKOUT_FORM_GIFTCARD_CODE", translation: "Gift Card Code" },
  { id: "CHECKOUT_FORM_GIFTCARD_PLACEHOLDER", translation: "XXXX-XXXX-XXXX" },
  {
    id: "CHECKOUT_GIFTCARD_ERROR_ALREADY_USED",
    translation: "The gift card has already been fully redeemed",
  },
  {
    id: "CHECKOUT_GIFTCARD_ERROR_INSUFFICIENT",
    translation: "The gift card does not have enough balance",
  },
  {
    id: "CHECKOUT_GIFTCARD_NOT_VALID",
    translation: "The gift card is no longer valid",
  },
  { id: "NOTIFY_ME_BUTTON", translation: "Meddele mig" },
  { id: "NOTIFY_ME_BUTTON_NOTIFIED", translation: "Du får besked" },
  { id: "NOTIFYME_FORM_HEADER_1", translation: "Giv mig besked når " },
  { id: "NOTIFYME_FORM_HEADER_2", translation: " bliver tilgængelig" },
  {
    id: "NOTIFYME_GENERIC_ERROR",
    translation: "Sorry, det fungerede ikke. Prøv igen senere.",
  },
  { id: "NOTIFYME_SEND_BUTTON", translation: "Meddele mig" },
  { id: "PRODUCT_SIZE_LABEL", translation: "Str." },
  { id: "PRODUCT_TYPE_LABEL", translation: "Type:" },
  { id: "PRODUCT_SIZE_GUIDE", translation: "Størrelsesguide" },
  {
    id: "SIZE_UP_DISCLAIMER",
    translation: "I tvivl? Gå en størrelse op.",
  },
  { id: "MORE_INFO_HEADER", translation: "Dokumentation påkrævet" },
  {
    id: "MORE_INFO_TEXT",
    translation:
      "Dette produkt kræver dokumentation for berettigelse, før ordren afsendes. Vi beder om, at der sendes en e-mail til 1@3charlie.no, fra en @mil.no e-mailadresse, som bekræfter kvalifikationen. Når denne er modtaget, sendes ordren til den opgivne adresse.",
  },
  {
    id: "MORE_INFO_TEXT_2",
    translation: "Tak for din forståelse.",
  },
  {
    id: "GALLERY_MORE_IMAGES_LINK",
    translation: "Flere billeder på Instagram",
  },
  {
    id: "CHECKOUT_FORM_GENERIC_ERROR",
    translation:
      "Betalingen blev gennemført, men ordren blev ikke registreret. Kontakt venligst 1@3charlie.no",
  },
  {
    id: "CHECKOUT_FORM_PAYMENT_ERROR",
    translation: "Din betaling blev ikke behandlet.",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_MISSING",
    translation: "⚠ Nødvendige felter mangler",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_CARD",
    translation: "⚠ Dine kortoplysninger er ikke gyldige",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_EMAIL",
    translation: "⚠ Din e-mail ser ikke ud til at være gyldig",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_PHONE",
    translation: "⚠ Du skal udfylde dit telefonnummer.",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_RESTRICTED_PRODUCTS",
    translation:
      "Shoppingsäcken innehåller produkter som inte kan skickas till det valda landet.",
  },
  {
    id: "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING",
    translation: "Shoppingsäcken innehåller begränsade produkter.",
  },
  {
    id: "CHECKOUT_FORM_RESTRICTED_PRODUCTS_WARNING_BUTTON",
    translation: "Tryck här för att se över.",
  },
  {
    id: "CHECKOUT_REMOVE_RESTRICTED_PRODUCTS_BUTTON",
    translation: "Ta bort begränsada produkter",
  },
  {
    id: "CHECKOUT_RESTRICTED_PRODUCT_LABEL  ",
    translation: "Begränsad",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_GIFTCARD",
    translation: "⚠ The gift card code is not valid",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_AGREEMENT",
    translation: "⚠ Accepter venligst købsaftalen",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_PHONE",
    translation: "⚠ Tjek venligst dit telefonnummer",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_POSTALCODE",
    translation: "⚠ Postnummeret er ikke gyldigt",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_NO_PICKUP_POINT",
    translation: "⚠ Du skal vælge en butik at hente i",
  },
  {
    id: "CHECKOUT_FORM_VALIDATION_ERROR_PICKUP_POINT",
    translation: "Ingen afhentningssteder fundet på dette postnummer.",
  },
  {
    id: "CHECKOUT_EMPTY_CART",
    translation: "Din kurv er tom.",
  },
  { id: "SHIPPING_TIME_MAILBOX", translation: "" },
  { id: "SHIPPING_TIME_PREFIX", translation: "Leveringstid" },
  {
    id: "SHIPPING_TIME_PREFIX_LONG_DISTANCE_STANDARD",
    translation: "Uden sporing. Leveringstid",
  },
  {
    id: "SHIPPING_TIME_PREFIX_LONG_DISTANCE_UPS",
    translation: "Med sporing. Leveringstid",
  },
  { id: "BOOM", translation: "Boom" },
  { id: "SHIPPING_TIME_DAYS", translation: "arbejdsdage." },
  { id: "ORDER_CONFIRMATION_HEADER", translation: "Ordren er gennemført" },
  {
    id: "ORDER_VIPPS_ERROR",
    translation:
      "Sorry, your payment could not be processed and the order was not registered.",
  },
  {
    id: "ORDER_VIPPS_MESSAGE",
    translation:
      "Thanks for your order. You will receive an email confirmation when your order is registered.",
  },
  {
    id: "ORDER_CONFIRMATION_EMAIL",
    translation: "En ordrebekræftelse er sendt til",
  },
  {
    id: "ORDER_CONFIRMATION_SPAM_DISCLAIMER",
    translation:
      "Tjek din spam-mappe, hvis du ikke kan finde din ordrebekræftelses-e-mail",
  },
  { id: "ORDER_CONFIRMATION_LINK", translation: "Gå til forsiden" },
  { id: "ORDER_CONFIRMATION_SUMMARY", translation: "Du bestilte" },
  {
    id: "ORDER_CONFIRMATION_GIFTCARD_REMAINING",
    translation: "Remaining on your gift card:",
  },
  { id: "ORDER_CONFIRM_DIALOG_HEADER", translation: "Bekræft din ordre" },
  { id: "ORDER_CONFIRM_DIALOG_VAT", translation: "Inkluderet moms" },
  {
    id: "ORDER_CONFIRM_DIALOG_NOK_CHARGE",
    translation: "Valutakurser er omtrentlige. Du vil blive opkrævet i NOK.",
  },
  { id: "ORDER_CONFIRM_DIALOG_BUY", translation: "Køb" },
  {
    id: "ORDER_CONFIRM_DIALOG_BUY_GIFTCARD",
    translation: "Buy with gift card",
  },
  { id: "ORDER_CONFIRM_DIALOG_CANCEL", translation: "Annuler" },
  { id: "GIFTCARD_PAGE_HEADER", translation: "Køb et gavekort" },
  { id: "GIFTCARD_LINK", translation: "Gavekort" },
  {
    id: "GIFTCARD_REDEEM_INFO",
    translation:
      "Redeem a gift card? Add items to the cart, go to checkout and select redeem gift card as payment method.",
  },
  { id: "CUSTOM_PATCHES_HEADER", translation: "Custom stuff" },
  {
    id: "ORDER_CONFIRM_DIALOG_PROCESSING",
    translation: "Arbejder på det",
  },
  {
    id: "ORDER_CONFIRM_DIALOG_PROCESSING_VIPPS",
    translation: "The payment may complete in another window.",
  },
  {
    id: "ORDER_CONFIRM_DIALOG_REDIRECT_VIPPS",
    translation: "Sending you over to Vipps",
  },
  {
    id: "CONFIRMATION_EMAIL_SUBJECT",
    translation: "Ordrebekræftelse",
  },
  {
    id: "TRACKING_EMAIL_SUBJECT",
    translation: "Spor din ordre",
  },
  {
    id: "TRACKING_EMAIL_BODY",
    translation: "Du kan spore din ordre via dette link: ",
  },
  {
    id: "TRACKING_EMAIL_CUSTOMS_FEE_DISCLAIMER",
    translation:
      "Bemærk venligst, at du muligvis skal betale et toldgebyr, før din ordre kan leveres.",
  },
  {
    id: "TRACKING_EMAIL_THANKS",
    translation: "Tak igen for ordren.",
  },
  { id: "CONFIRMATION_EMAIL_HI", translation: "Hej" },
  { id: "CONFIRMATION_EMAIL_UNITS", translation: "stk." },
  { id: "CONFIRMATION_EMAIL_VAT", translation: "Hereof VAT" },
  { id: "CONFIRMATION_EMAIL_THANKS", translation: "Tak for din ordre" },
  {
    id: "CONFIRMATION_EMAIL_INFO",
    translation: "Din ordre bliver sendt ASAP.",
  },
  {
    id: "CONFIRMATION_EMAIL_AGREEMENT",
    translation: "Annulleringsformular",
  },
  { id: "CONFIRMATION_EMAIL_SIGNATURE", translation: "3charlie slutt" },
  { id: "COOKIE_CONSENT_BUTTON", translation: "Jeg accepterer" },
  {
    id: "COOKIE_CONSENT_TEXT",
    translation:
      'Dette websted bruger cookies. Tryk på "Jeg accepterer" for at give samtykke.',
  },
  {
    id: "PURCHASE_AGREEMENT_CONFIRMATION_HEADER",
    translation: "Ordrebekræftelse",
  },
  {
    id: "PURCHASE_AGREEMENT_CONFIRMATION_TEXT",
    translation:
      "3charlie sender dig en ordrebekræftelse via e-mail, så snart din ordre er behandlet. Sørg for at indtaste din korrekte e-mail-adresse. Hvis du ikke modtager en ordrebekræftelse via e-mail, skal du sende en besked til",
  },
  {
    id: "PURCHASE_AGREEMENT_PAYMENT_HEADER",
    translation: "Betaling",
  },
  {
    id: "PURCHASE_AGREEMENT_PAYMENT_TEXT",
    translation:
      "3charlie opkræver køberen på bestillingstidspunktet. Valutakurser er omtrentlige. Du vil blive opkrævet i NOK. Hvis der er et problem med ordren, og den ikke kan gennemføres, tilbagebetaler sælgeren hele beløbet til det betalingskort, der blev brugt ved bestilling.",
  },
  {
    id: "PURCHASE_AGREEMENT_SHIPPING_HEADER",
    translation: "Fragt og gebyrer",
  },
  {
    id: "PURCHASE_AGREEMENT_SHIPPING_TEXT",
    translation: `3charlie sender ordren til dig med Posten Norge. Forsendelse er gratis ved køb over ${FREE_SHIPPING_OTHER} NOK til adresser i Danmark. Inden du bekræfter din ordre, skal du sikre dig, at den indtastede adresse er korrekt.`,
  },
  {
    id: "PURCHASE_AGREEMENT_DELIVERY_HEADER",
    translation: "Levering",
  },
  {
    id: "PURCHASE_AGREEMENT_DELIVERY_TEXT",
    translation:
      "Din ordre vil blive sendt til din adresse så hurtigt som muligt. Normal leveringstid til Danmark er 6-7 hverdage.",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_HEADER",
    translation: "Aflysninger og returneringer",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_1",
    translation:
      "Køberen skal underrette sælgeren om brugen af ​​fortrydelsesretten inden for 14 dage fra fristen begynder at løbe. Annulleringsperioden begynder at løbe fra dagen efter, at varen (e) er modtaget. Fristen inkluderer alle kalenderdage. Hvis fristen udløber en lørdag eller helligdag, forlænges fristen til nærmeste arbejdsdag. Send en besked til",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_2",
    translation:
      "for at annullere din ordre. Afbestillingsfristen anses for at være i overensstemmelse, hvis meddelelsen sendes inden udløbet af fristen. Hvis ordren allerede er afsendt inden køberen udøver fortrydelsesretten, skal du udfylde",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_LINK",
    translation: "annulleringsformularen",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_3",
    translation: ", og returner varen(e) samt annulleringsformularen til:",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_4",
    translation: "Køberen skal betale for returforsendelsen.",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_5",
    translation:
      "Køberen kan prøve eller teste varen på en ansvarlig måde for at bestemme dens art, egenskaber og funktion uden at fortrydelsesretten bortfalder. Hvis test af varen går ud over, hvad der er berettiget og nødvendigt, kan køberen være ansvarlig for enhver nedsat værdi af varen.",
  },
  {
    id: "PURCHASE_AGREEMENT_CANCELLATION_TEXT_6",
    translation:
      "Sælger er forpligtet til at tilbagebetale købsbeløbet til køberen uden unødig forsinkelse, og senest 14 dage fra sælger blev underrettet om købers beslutning om at udøve fortrydelsesretten. Sælger har ret til at tilbageholde betaling, indtil han har modtaget varen fra køberen, eller indtil køberen har fremlagt dokumentation for, at varen er returneret.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_HEADER",
    translation: "Klager",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_1",
    translation:
      "Hvis der er en mangel på varen, skal køberen inden for en rimelig tid efter, at den blev opdaget, eller burde have været opdaget, meddele sælgeren, at køberen påberåber sig klagen. Køberen har altid klaget i god tid, hvis det sker inden for 2 måneder fra manglen blev opdaget eller burde have været opdaget.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_2",
    translation:
      "Hvis varen har en mangel, og dette ikke skyldes køberen eller forholdene på købers side, kan køberen vælge mellem genlevering, kræve en prisnedsættelse eller kræve, at aftalen opsiges.",
  },
  {
    id: "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_3",
    translation: "Klager skal indsendes pr. e-mail til",
  },
  {
    id: "PURCHASE_AGREEMENT_DISPUTES_HEADER",
    translation: "Tvister",
  },
  {
    id: "PURCHASE_AGREEMENT_DISPUTES_TEXT",
    translation:
      "Tvister skal rettes til sælgeren inden for en rimelig tid. Parterne bestræber sig på at løse eventuelle tvister i mindelighed. Hvis dette ikke lykkes, kan køberen kontakte det norske forbrugerråd for mægling.",
  },
  {
    id: "PURCHASE_AGREEMENT_PRIVACY_HEADER",
    translation: "Personvern",
  },
  {
    id: "PURCHASE_AGREEMENT_PRIVACY_TEXT",
    translation:
      "Dit navn, adresse, telefonnummer og e-mail gemmes af 3charlie. Disse oplysninger indsamles udelukkende for at 3charlie kan sende ordrebekræftelsen og selve ordren til dig, eller alternativt for at kontakte dig hvis der er et problem med din ordre. Personlige oplysninger deles ikke med andre parter. Hvis du ønsker adgang til dine personlige data. eller ønsker at dine personlige data slettes fra 3charlie, bedes du kontakte",
  },
  { id: "CLOSE", translation: "Luk" },
  {
    id: "EXTRA_DELIVERY_TIME",
    translation: "🚛 Bemærk, at leveringstiden kan være lidt længere i juli 🏖",
  },
];
