import React from "react";
import Strapi from "strapi-sdk-javascript/build/main";
import Lightbox from "react-image-lightbox";
import { dashToSpace } from "../utils/helperFunctions";
import {
  getLanguageFromBrowser,
  getLanguageFromCookie,
  getLanguageFromParams,
  getStrapiUrl,
  translate,
  getCart,
  setCart,
  jegerbueId,
  calculateAmount,
  validEmailAddress,
  showSizeGuideButton,
  setLanguageCookie,
} from "../utils";
import {
  itemsInCart,
  renderFreeShippingText,
  mapToCartSize,
  setCampaignLabel,
} from "../utils/cartFunctions";
import Modal from "./Modal";
import Navbar from "./Navbar";
import { StageSpinner } from "react-spinners-kit";
import "./ProductView.scss";
import { observer } from "mobx-react";
import { productsStore } from "../productsStore";
import { NavLink } from "react-router-dom";
const strapi = new Strapi(getStrapiUrl());

function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName].length === 0
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

class ProductView extends React.Component {
  state = {
    websiteLanguage: "no",
    loadingProduct: true,
    productName: this.props.match.params.name,
    product: "",
    cartItems: [],
    modalOpen: false,
    selectedProductInSizeModal: undefined,
    itemAddedToCart: false,
    showMobileCart: false,
    qualificationDisclaimerAccepted: false,
    sizesSelected: [],
    showNotifyMeForm: false,
    notifyMeItem: undefined,
    notifyMeEmailAddress: "",
    emailValidationError: undefined,
    sendingNotifyMeEmail: false,
    notifyMeSubmitted: false,
    notifyMeSubmittedItem: "",
    adsRedirect: false,
    galleryImages: [],
    imageModalIndex: 0,
    imageModalOpen: false,
    imageModalItem: undefined,
    variantsSelected: [],
    productsFromStore: null,
  };

  async componentDidMount() {
    const productName = dashToSpace(this.props.match.params.name);

    document.querySelector("html").setAttribute("style", "overflow: visible;");

    if (getLanguageFromParams() === "no") {
      this.setState({ websiteLanguage: "no", adsRedirect: true });
      setLanguageCookie("no");
    } else if (getLanguageFromCookie()) {
      if (getLanguageFromCookie() === "no") {
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromCookie() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromCookie() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    } else {
      if (getLanguageFromBrowser() === "no") {
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromBrowser() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromBrowser() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    }

    const productsFromStore = productsStore.allProducts;
    let productToView = undefined;

    if (productsFromStore && productsFromStore.length !== 0) {
      productToView = productsFromStore
        .find((category) =>
          category.products.find((item) => item.name === productName)
        )
        .products.find((item) => item.name === productName);
    } else {
      try {
        const response = await strapi.request("POST", "/graphql", {
          data: {
            query: `query {
  
              patches(where: {name: "${productName}"}) {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
              }
  
              smallpatches(where: {name: "${productName}"}) {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                types
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
              }
  
              gadgets(where: {name: "${productName}"}) {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
              }
  
              tshirts(where: {name: "${productName}"}) {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                sizes
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                showSizeGuideButton
                sizeGuide
                fullProductTitle
                productDataLink
                packageSize
                exportShippingInfo
              }
  
              rankpatches(where: {name: "${productName}"}) {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                types
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
              }
  
              adhesives(where: {name: "${productName}"}) {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                types
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
              }
            }
            `,
          },
        });
        const data = response.data;
        const productObject = clean(data);
        productToView = productObject[Object.keys(productObject)[0]][0];
      } catch (err) {
        console.log("could not load product from graphql query:", err);
        this.setState({
          loadingProduct: false,
        });
      }
    }
    this.setState({
      loadingProduct: false,
      product: productToView,
      cartItems: getCart(),
    });
  }

  renderDescriptionLanguage = (product, websiteLanguage) => {
    if (websiteLanguage === "no") {
      return product.description;
    } else if (websiteLanguage === "sv") {
      if (product.swedishDescription) {
        return product.swedishDescription;
      }
      return product.englishDescription;
    } else if (websiteLanguage === "da") {
      if (product.danishDescription) {
        return product.danishDescription;
      }
      return product.englishDescription;
    } else {
      return product.englishDescription;
    }
  };

  handleAddToCart = (product, adding) => {
    let alreadyInCart = false;
    let sizedProduct = undefined;

    if (
      product._id === jegerbueId &&
      !this.state.qualificationDisclaimerAccepted
    ) {
      this.setState(
        {
          modalOpen: true,
          selectedProductInSizeModal: product,
        },
        () => {
          document
            .querySelector("html")
            .setAttribute("style", "overflow: hidden;");
        }
      );
      return;
    }

    // Check if product has variants
    let productHasVariants = false;
    if (product.variants && Object.keys(product.variants).length !== 0) {
      productHasVariants = true;
    }

    if (productHasVariants) {
      sizedProduct = { ...product };
      if (adding) {
        sizedProduct.name = product.name;
      } else {
        const variantsSelected = this.state.variantsSelected;
        const selectedVariantIndex = variantsSelected.findIndex(
          (item) => item.productId === product._id
        );
        sizedProduct.name =
          variantsSelected[selectedVariantIndex].productVariantName;
      }
      alreadyInCart = this.state.cartItems.findIndex(
        (item) => item.name === sizedProduct.name
      );
    } else {
      alreadyInCart = this.state.cartItems.findIndex(
        (item) => item._id === product._id
      );
    }

    if (alreadyInCart === -1) {
      let updatedItems = undefined;
      if (productHasVariants) {
        updatedItems = this.state.cartItems.concat({
          ...sizedProduct,
          quantity: 1,
        });
      } else {
        updatedItems = this.state.cartItems.concat({
          ...product,
          quantity: 1,
        });
      }
      this.setState({ cartItems: updatedItems }, () => setCart(updatedItems));
    } else if (product._id !== "customOrder") {
      const updatedItems = [...this.state.cartItems];
      updatedItems[alreadyInCart].quantity += 1;
      this.setState({ cartItems: updatedItems }, () => setCart(updatedItems));
    }
    this.updateCartBadge();
  };

  removeItemFromCart = (product) => {
    let alreadyInCart = undefined;

    if (product.sizes || product.types) {
      alreadyInCart = this.state.cartItems.findIndex(
        (item) => item._id === product._id && item.name === product.name
      );
    } else {
      alreadyInCart = this.state.cartItems.findIndex(
        (item) => item._id === product._id
      );
    }

    const updatedItems = [...this.state.cartItems];

    if (updatedItems[alreadyInCart].quantity > 1) {
      updatedItems[alreadyInCart].quantity -= 1;
      this.setState({ cartItems: updatedItems }, () => setCart(updatedItems));
    } else {
      this.deleteItemFromCart(product);
    }
    this.updateCartBadge();
  };

  deleteItemFromCart = (product) => {
    let filteredItems = undefined;
    if (product.sizes || product.types) {
      filteredItems = this.state.cartItems.filter(
        (item) => item.name !== product.name
      );
    } else {
      filteredItems = this.state.cartItems.filter(
        (item) => item._id !== product._id
      );
    }
    this.setState(
      {
        cartItems: filteredItems,
        showMobileCart: filteredItems.length > 0,
      },
      () => setCart(filteredItems)
    );

    if (filteredItems.length === 0) {
      document
        .querySelector("html")
        .setAttribute("style", "overflow: visible;");
    }
  };

  updateCartBadge() {
    this.setState({ itemAddedToCart: true });
    setTimeout(() => {
      this.setState({ itemAddedToCart: false });
    }, 300);
  }

  renderAmountDecimals = (websiteLanguage) => {
    let decimals = ",00 ";
    if (websiteLanguage === "en") {
      decimals = ".00 ";
    }
    return decimals;
  };

  setSelectedSizeClassName = (size, product) => {
    const selectedSizes = this.state.sizesSelected;
    const productSizeName = `${product.name} ${mapToCartSize(size)}`;
    const productId = product._id;

    const productAlreadySelected = selectedSizes.findIndex(
      (item) => item.productId === productId
    );

    if (productAlreadySelected === -1) {
      return "selected";
    } else if (
      selectedSizes[productAlreadySelected].productSizeName === productSizeName
    ) {
      return "selected";
    } else {
      return "unselected";
    }
  };

  setVariantDropdownLabelText = (variants) => {
    if (variants[0][1].variantIsSize) {
      return translate(this.state.websiteLanguage, "PRODUCT_SIZE_LABEL");
    }
    return translate(this.state.websiteLanguage, "PRODUCT_TYPE_LABEL");
  };

  getSelectedProductVariant = (product) => {
    let productHasVariants = false;
    // Check if product has variants
    if (product.variants && Object.keys(product.variants).length !== 0) {
      productHasVariants = true;
    }
    // Check if product has already been selected
    const productVariantSelectedIndex = this.state.variantsSelected.findIndex(
      (item) => item.productId === product._id
    );
    const productVariantAlreadySelected = productVariantSelectedIndex !== -1;
    if (productHasVariants && productVariantAlreadySelected) {
      return this.state.variantsSelected[productVariantSelectedIndex].variant;
    } else {
      return undefined;
    }
  };

  setSelectedProductVariant = (selectedVariant, product, productVariants) => {
    const variantIndex = productVariants.findIndex(
      (item) => item[0] === selectedVariant
    );
    const variantCartName = productVariants[variantIndex][1].cartName;
    const variantImageUrl = productVariants[variantIndex][1].imageUrl;
    const variantInStock = productVariants[variantIndex][1].inStock;

    let variantAlreadySelected = undefined;
    let updatedVariantsSelected = undefined;
    const productName = product.name;
    const productId = product._id;
    const variantsSelected = this.state.variantsSelected;
    variantAlreadySelected = variantsSelected.findIndex(
      (item) => item.productId === productId
    );
    if (variantAlreadySelected === -1) {
      updatedVariantsSelected = variantsSelected.concat({
        ...{
          productId: productId,
          productVariantName: `${productName} ${variantCartName}`,
          variant: selectedVariant,
          variantImageUrl: variantImageUrl,
          variantInStock: variantInStock,
        },
      });
      this.setState({ variantsSelected: updatedVariantsSelected });
    } else {
      variantsSelected[variantAlreadySelected] = {
        productId: productId,
        productVariantName: `${productName} ${variantCartName}`,
        variant: selectedVariant,
        variantImageUrl: variantImageUrl,
        variantInStock: variantInStock,
      };
      updatedVariantsSelected = variantsSelected;
      this.setState({ variantsSelected: updatedVariantsSelected });
    }
  };

  renderVariantDropdown = (product) => {
    if (
      !product.variants ||
      !Object.keys(product.variants) ||
      Object.keys(product.variants).length === 0
    ) {
      return;
    }

    // Convert to array
    let productVariants = Object.entries(product.variants);

    // Sort alphabetically if types only
    if (!productVariants[0][1].variantIsSize) {
      productVariants = productVariants.sort((a, b) => {
        if (a[0] < b[0]) {
          return -1;
        }
        if (a[0] > b[0]) {
          return 1;
        }
        return 0;
      });
    }

    const selectedVariant = this.getSelectedProductVariant(product);

    return (
      <span className="type-dropdown">
        <span className="size-label">
          {this.setVariantDropdownLabelText(Object.entries(product.variants))}
        </span>
        <select
          name="type"
          id="type-dropdown"
          value={selectedVariant ? selectedVariant : "default"}
          onChange={(e) =>
            this.setSelectedProductVariant(
              e.target.value,
              product,
              productVariants
            )
          }
        >
          <option value="default" disabled>
            {translate(this.state.websiteLanguage, "FRONTPAGE_ADD_CHOOSE")}
          </option>
          {productVariants.map((item, key) => {
            return (
              <option key={key} value={item[0]}>
                {item[1].dropdownName}
              </option>
            );
          })}
        </select>
        <div className="select-dropdown-arrow" />
      </span>
    );
  };

  setDisabledAddToCartButton = (product) => {
    let productHasVariants = false;
    // Check to see if there are variants
    if (product.variants && Object.keys(product.variants).length !== 0) {
      productHasVariants = true;
    }
    // Check to see if the product already has a selected variant
    const productVariantSelectedIndex = this.state.variantsSelected.findIndex(
      (item) => item.productId === product._id
    );
    const productVariantAlreadySelected = productVariantSelectedIndex !== -1;

    if (product.itemsLeft === 0) {
      // There are no items left of the product
      return true;
    } else if (productHasVariants && !productVariantAlreadySelected) {
      // The product has variants but none are selected
      return true;
    } else if (productHasVariants && productVariantAlreadySelected) {
      if (
        !this.state.variantsSelected[productVariantSelectedIndex].variantInStock
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  setSelectedSizes = (selectedSize, product) => {
    let productAlreadySelected = undefined;
    let updatedItems = undefined;
    const productName = product.name;
    const productId = product._id;
    const sizesSelected = this.state.sizesSelected;
    productAlreadySelected = sizesSelected.findIndex(
      (item) => item.productId === productId
    );
    if (productAlreadySelected === -1) {
      updatedItems = sizesSelected.concat({
        ...{
          productId: productId,
          productSizeName: `${productName} ${mapToCartSize(selectedSize)}`,
          size: selectedSize,
        },
      });
      this.setState({ sizesSelected: updatedItems });
    } else {
      sizesSelected[productAlreadySelected] = {
        productId: productId,
        productSizeName: `${productName} ${mapToCartSize(selectedSize)}`,
        size: selectedSize,
      };
      updatedItems = sizesSelected;
      this.setState({ sizesSelected: updatedItems });
    }
    if (selectedSize === "OR2") {
      this.setState({ rankPatchImage: 0 });
    } else if (selectedSize === "OR3") {
      this.setState({ rankPatchImage: 1 });
    } else if (selectedSize === "OR4") {
      this.setState({ rankPatchImage: 2 });
    } else if (selectedSize === "OR4P") {
      this.setState({ rankPatchImage: 3 });
    } else if (selectedSize === "OR5") {
      this.setState({ rankPatchImage: 4 });
    } else if (selectedSize === "OR5P") {
      this.setState({ rankPatchImage: 5 });
    } else if (selectedSize === "OR6") {
      this.setState({ rankPatchImage: 6 });
    } else if (selectedSize === "OR6S") {
      this.setState({ rankPatchImage: 7 });
    } else if (selectedSize === "OR7") {
      this.setState({ rankPatchImage: 8 });
    } else if (selectedSize === "OR7S") {
      this.setState({ rankPatchImage: 9 });
    } else if (selectedSize === "OR8") {
      this.setState({ rankPatchImage: 10 });
    } else if (selectedSize === "OR8S") {
      this.setState({ rankPatchImage: 11 });
    } else if (selectedSize === "OR9") {
      this.setState({ rankPatchImage: 12 });
    } else if (selectedSize === "OR9S") {
      this.setState({ rankPatchImage: 13 });
    } else if (selectedSize === "Normal") {
      this.setState({ smallPatchImage: "normal" });
    } else if (selectedSize === "Reverse") {
      this.setState({ smallPatchImage: "reverse" });
    } else if (selectedSize === "0_OR2_COYOTE") {
      this.setState({ rankPatchImageCoyote: 0 });
    } else if (selectedSize === "1_OR5_COYOTE") {
      this.setState({ rankPatchImageCoyote: 4 });
    } else if (selectedSize === "2_OR5P_COYOTE") {
      this.setState({ rankPatchImageCoyote: 5 });
    } else if (selectedSize === "3_OR6S_COYOTE") {
      this.setState({ rankPatchImageCoyote: 1 });
    } else if (selectedSize === "4_OF1_COYOTE") {
      this.setState({ rankPatchImageCoyote: 3 });
    } else if (selectedSize === "5_OF2_COYOTE") {
      this.setState({ rankPatchImageCoyote: 2 });
    }
  };

  setNotifyByEmail = (product, websiteLanguage) => {
    const itemName = product.name;
    const itemsLeft = product.itemsLeft;
    const productId = product._id;
    const variantsSelected = this.state.variantsSelected;

    // Check if product has already been selected
    const productVariantSelectedIndex = variantsSelected.findIndex(
      (item) => item.productId === productId
    );
    const productVariantAlreadySelected = productVariantSelectedIndex !== -1;
    let selectedVariantInStock = true;
    if (productVariantAlreadySelected) {
      selectedVariantInStock =
        variantsSelected[productVariantSelectedIndex].variantInStock;
    }

    if (itemsLeft === 0 || !selectedVariantInStock) {
      return (
        <div>
          <button
            className={`notify-in-stock${
              this.state.submittedItem === itemName ? "  disabled" : ""
            }`}
            onClick={() => this.notifyMeClick(itemName)}
          >
            <img
              className={`notification-icon${
                this.state.submittedItem === itemName ? " animation" : ""
              }`}
              alt="logo"
              src="./icons/notification.svg"
            />
            {this.state.submittedItem === itemName
              ? translate(websiteLanguage, "NOTIFY_ME_BUTTON_NOTIFIED")
              : translate(websiteLanguage, "NOTIFY_ME_BUTTON")}
          </button>
        </div>
      );
    }
    return null;
  };

  notifyMeClick = (itemName) => {
    this.setState(
      {
        showNotifyMeForm: false,
        notifyMeItem: itemName,
        submittedItem: "",
      },
      () => this.setState({ showNotifyMeForm: true })
    );
  };

  renderNotifyMeForm = (product) => {
    let itemName = product.name;

    let productVariantSelectedNotInStock = false;
    let itemVariantName = "";
    // Check to see if there are variants
    if (product.variants && Object.keys(product.variants).length !== 0) {
      const variantsSelected = this.state.variantsSelected;

      // Check if product has already been selected
      const productVariantSelectedIndex = variantsSelected.findIndex(
        (item) => item.productId === product._id
      );
      const productVariantAlreadySelected = productVariantSelectedIndex !== -1;
      let selectedVariantInStock = true;
      if (productVariantAlreadySelected) {
        selectedVariantInStock =
          variantsSelected[productVariantSelectedIndex].variantInStock;
        if (!selectedVariantInStock) {
          productVariantSelectedNotInStock = true;
          itemVariantName =
            variantsSelected[productVariantSelectedIndex].productVariantName;
        }
      }
    }

    if (this.state.showNotifyMeForm && this.state.notifyMeItem === itemName) {
      return (
        <div className="notify-me-form">
          <button
            className="close-notify-form-button"
            onClick={() => this.handleCloseNotifyMeForm()}
          >
            <img alt="close" src="./icons/close-icon.svg" />
          </button>
          <div className="notify-me-form-header">
            {translate(this.state.websiteLanguage, "NOTIFYME_FORM_HEADER_1")}
            {productVariantSelectedNotInStock ? itemVariantName : itemName}
            {translate(this.state.websiteLanguage, "NOTIFYME_FORM_HEADER_2")}
          </div>
          <input
            id="notifyMeEmailAddress"
            type="text"
            name="notifyMeEmailAddress"
            placeholder={translate(
              this.state.websiteLanguage,
              "CHECKOUT_FORM_EMAIL_PLACEHOLDER"
            )}
            onFocus={this.handleFocus}
            onChange={this.handleChange.bind(this)}
            value={this.state.notifyMeEmailAddress}
          />
          {this.state.emailValidationError && (
            <div className="notify-me-error">
              {this.state.emailValidationError}
            </div>
          )}
          <button
            className="notify-me-send-button"
            disabled={this.state.sendingNotifyMeEmail}
            onClick={() => this.handleNotifyMe(itemName, itemVariantName)}
          >
            {this.state.sendingNotifyMeEmail ? (
              <StageSpinner
                size={21}
                loading={this.state.sendingNotifyMeEmail}
                color="#000000"
              />
            ) : (
              translate(this.state.websiteLanguage, "NOTIFYME_SEND_BUTTON")
            )}
          </button>
        </div>
      );
    }
    return null;
  };

  handleCloseNotifyMeForm = () => {
    this.setState({
      showNotifyMeForm: false,
      notifyMeEmailAddress: "",
      notifyMeItem: undefined,
      emailValidationError: undefined,
      sendingNotifyMeEmail: false,
      notifyMeSubmitted: false,
    });
  };

  handleNotifyMe = async (itemName, itemVariantName) => {
    if (!validEmailAddress(this.state.notifyMeEmailAddress)) {
      document
        .querySelector("#notifyMeEmailAddress")
        .classList.add("validation-error");
      this.setState({
        emailValidationError: translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_VALIDATION_EMAIL"
        ),
      });
    } else {
      this.setState({ sendingNotifyMeEmail: true });
      try {
        await strapi.createEntry("notifications", {
          email: this.state.notifyMeEmailAddress,
          product: itemVariantName ? itemVariantName : itemName,
          time: `${new Date(Date.now())}`,
          language: this.state.websiteLanguage,
        });
      } catch (e) {
        this.setState({
          sendingNotifyMeEmail: false,
          emailValidationError: translate(
            this.state.websiteLanguage,
            "NOTIFYME_GENERIC_ERROR"
          ),
        });
      }
      this.setState(
        {
          sendingNotifyMeEmail: false,
          notifyMeSubmitted: true,
        },
        () => this.handleNotifyMeSubmitted(itemName)
      );
    }
  };

  handleNotifyMeSubmitted = (itemName) => {
    this.setState({ submittedItem: itemName });
    this.handleCloseNotifyMeForm();
  };

  handleChange = (e) => {
    e.persist();
    this.setState({ notifyMeEmailAddress: e.target.value });
  };

  handleFocus = (e) => {
    this.setState({ emailValidationError: undefined });
    if (e.target && e.target.classList.contains("validation-error")) {
      e.target.classList.remove("validation-error");
    }
  };

  sizeGuideButtonClick = (e, product) => {
    e.stopPropagation();
    this.setState(
      { modalOpen: true, selectedProductInSizeModal: product },
      () => {
        document
          .querySelector("html")
          .setAttribute("style", "overflow: hidden;");
      }
    );
    if (this.state.sizesSelected.length > 0) {
      const productAlreadySelectedIndex = this.state.sizesSelected.findIndex(
        (item) => item.productId === product._id
      );
      if (
        this.state.sizesSelected[productAlreadySelectedIndex] &&
        this.state.sizesSelected[productAlreadySelectedIndex] !== -1
      ) {
        this.setState({
          sizeSelectedModal:
            this.state.sizesSelected[productAlreadySelectedIndex].size,
        });
      }
    }
  };

  setSelectedSizeModal = (size) => {
    this.setState({ sizeSelectedModal: size });
  };

  closeModal = () => {
    this.setState({ modalOpen: false, sizeSelectedModal: undefined }, () => {
      document
        .querySelector("html")
        .setAttribute("style", "overflow: visible;");
    });
  };

  productImageClick = () => {
    document.querySelector(".product-images-list").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  renderMoreImagesIcon = (product) => {
    if (product.imageGallery && product.imageGallery.length) {
      return (
        <img
          className="product-more-images-icon"
          alt="See more photos"
          src="./icons/more-images-icon.svg"
        />
      );
    }
  };

  renderGalleryModal = (product) => {
    const { imageModalIndex, galleryImages } = this.state;
    if (product.name === this.state.imageModalItem) {
      return (
        <Lightbox
          mainSrc={galleryImages[imageModalIndex]}
          nextSrc={galleryImages[(imageModalIndex + 1) % galleryImages.length]}
          prevSrc={
            galleryImages[
              (imageModalIndex + galleryImages.length - 1) %
                galleryImages.length
            ]
          }
          onCloseRequest={() =>
            this.setState({
              imageModalOpen: false,
              galleryImages: [],
              imageModalIndex: 0,
            })
          }
          onMovePrevRequest={() =>
            this.setState({
              imageModalIndex:
                (imageModalIndex + galleryImages.length - 1) %
                galleryImages.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              imageModalIndex: (imageModalIndex + 1) % galleryImages.length,
            })
          }
          enableZoom={false}
          imageCaption={
            <a
              className="notify-instagram-link"
              href="https://www.instagram.com/3charlie.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate(
                this.state.websiteLanguage,
                "GALLERY_MORE_IMAGES_LINK"
              )}
            </a>
          }
        />
      );
    }
  };

  renderProductImagesList = (product) => {
    const images = product.imageGallery.map((item, key) => (
      <img
        className="product-images-list-item"
        key={key}
        src={item.url}
        alt={item.name}
      />
    ));
    return <div className="product-images-list">{images}</div>;
  };

  checkboxOnChange = () => {
    this.setState({
      qualificationDisclaimerAccepted:
        !this.state.qualificationDisclaimerAccepted,
    });
  };

  addToCartButtonText = (product) => {
    // Check if product has no items in stock at all
    if (product.itemsLeft === 0) {
      return translate(this.state.websiteLanguage, "FRONTPAGE_OUT_OF_STOCK");
    }
    let productHasVariants = false;
    // Check if product has variants
    if (product.variants && Object.keys(product.variants).length !== 0) {
      productHasVariants = true;
    }
    // Check if product has already been selected
    const productVariantSelectedIndex = this.state.variantsSelected.findIndex(
      (item) => item.productId === product._id
    );
    const productVariantAlreadySelected = productVariantSelectedIndex !== -1;
    if (productHasVariants && !productVariantAlreadySelected) {
      // Check to see if the product already has a selected variant
      return this.setVariantButtonText(Object.entries(product.variants));
    } else if (productHasVariants && productVariantAlreadySelected) {
      if (
        !this.state.variantsSelected[productVariantSelectedIndex].variantInStock
      ) {
        return translate(this.state.websiteLanguage, "FRONTPAGE_OUT_OF_STOCK");
      } else {
        return translate(this.state.websiteLanguage, "FRONTPAGE_ADD_TO_CART");
      }
    } else if (
      product._id === jegerbueId &&
      !this.state.qualificationDisclaimerAccepted
    ) {
      return translate(this.state.websiteLanguage, "FRONTPAGE_MORE_INFO");
    } else {
      return translate(this.state.websiteLanguage, "FRONTPAGE_ADD_TO_CART");
    }
  };

  setVariantDropdownLabelText = (variants) => {
    if (variants[0][1].variantIsSize) {
      return translate(this.state.websiteLanguage, "PRODUCT_SIZE_LABEL");
    }
    return translate(this.state.websiteLanguage, "PRODUCT_TYPE_LABEL");
  };

  setVariantButtonText = (variants) => {
    if (variants[0][1].variantIsSize) {
      return translate(this.state.websiteLanguage, "FRONTPAGE_ADD_CHOOSE_SIZE");
    }
    return translate(this.state.websiteLanguage, "FRONTPAGE_ADD_CHOOSE_TYPE");
  };

  setSelectedVariantImage = (product) => {
    const selectedVariants = this.state.variantsSelected;
    const selectedVariantIndex = selectedVariants.findIndex(
      (item) => item.productId === product._id
    );
    if (
      selectedVariantIndex !== -1 &&
      selectedVariants[selectedVariantIndex].variantImageUrl
    ) {
      return selectedVariants[selectedVariantIndex].variantImageUrl;
    }
    if (product.image.length) {
      return product.image[0].url;
    }
    return product.image.url;
  };

  render() {
    const {
      product,
      loadingProduct,
      websiteLanguage,
      cartItems,
      showMobileCart,
      modalOpen,
      imageModalOpen,
    } = this.state;

    if (!product && !loadingProduct) {
      return (
        <div className="page-not-found-container">
          <Navbar
            showBackButton={true}
            websiteLanguage={websiteLanguage}
            transparent={false}
            backButtonTextRef={"HEADER_ALL_PRODUCTS"}
          />
          <div id="page-not-found">
            <div className="text-container">
              Gått på kvist? Finner ikke siden :/
            </div>
          </div>
        </div>
      );
    }

    if (loadingProduct) {
      return (
        <div className="root-container spinner-container">
          <div className="spinner-bg">
            <StageSpinner loading={loadingProduct} color="#bcf135" />
          </div>
        </div>
      );
    }

    return (
      <div
        className={`root-container product-landing-page ${
          cartItems.length > 0 ? "cart-visible" : "cart-hidden"
        }`}
      >
        {modalOpen && (
          <Modal
            close={this.closeModal}
            product={this.state.selectedProductInSizeModal}
            websiteLanguage={this.state.websiteLanguage}
            sizeSelectedModal={this.state.sizeSelectedModal}
            setSelectedSizeModal={this.setSelectedSizeModal}
            setSelectedSizeClassName={this.setSelectedSizeClassName}
            modalClickOutside={this.modalClickOutside}
            checkboxChecked={this.state.qualificationDisclaimerAccepted}
            checkboxOnChange={this.checkboxOnChange}
            productVariantSelected={this.getSelectedProductVariant(
              this.state.selectedProductInSizeModal
            )}
            setSelectedProductVariant={this.setSelectedProductVariant}
          />
        )}
        <Navbar
          showBackButton={true}
          websiteLanguage={websiteLanguage}
          transparent={false}
          backButtonTextRef={"HEADER_ALL_PRODUCTS"}
        />
        <section className="section-product-view">
          <div className="image-container">
            {product.label && (
              <div className="campaign-label">
                {setCampaignLabel(product.label, websiteLanguage)}
              </div>
            )}
            {imageModalOpen &&
              product.imageGallery &&
              product.imageGallery.length > 0 &&
              this.renderGalleryModal(product)}
            <div
              className={`product-image ${
                product.imageGallery && product.imageGallery.length > 0
                  ? "gallery"
                  : "no-gallery"
              }`}
              onClick={() => this.productImageClick()}
            >
              <img
                src={this.setSelectedVariantImage(product)}
                alt={`Bilde av ${product.name}`}
              />
              {this.renderMoreImagesIcon(product)}
            </div>
          </div>
          <div className="text-container">
            <div className="product-name">{product.name}</div>
            <div className="product-description">
              {this.renderDescriptionLanguage(product, websiteLanguage)}

              {product.fullProductTitle && product.productDataLink && (
                <p className="product-data-link-label">
                  {translate(websiteLanguage, "FRONTPAGE_MORE_INFO")}:{" "}
                  {
                    <a
                      className="product-data-link"
                      href={product.productDataLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {product.fullProductTitle}
                    </a>
                  }
                </p>
              )}
            </div>
            <div className="product-price">
              {product.price}
              {this.renderAmountDecimals(websiteLanguage)}{" "}
              {translate(websiteLanguage, "CURRENCY_NORWEGIAN")}
              {this.renderVariantDropdown(product)}
            </div>
            {showSizeGuideButton(product) && (
              <button
                className="size-guide-button"
                onClick={(e) => this.sizeGuideButtonClick(e, product)}
              >
                {translate(websiteLanguage, "PRODUCT_SIZE_GUIDE")}
              </button>
            )}
            <div className="product-cart-button">
              <button
                onClick={() => this.handleAddToCart(product, false)}
                disabled={this.setDisabledAddToCartButton(product)}
                className="product-buy-button"
              >
                {this.addToCartButtonText(product)}
              </button>
            </div>
            {this.setNotifyByEmail(product, websiteLanguage)}
            {this.renderNotifyMeForm(product)}
          </div>
          <div
            className={`all-products-button ${
              cartItems.length ? "cart-visible" : "cart-hidden"
            }`}
          >
            <NavLink className="all-products-link" to="/">
              {translate(websiteLanguage, "HEADER_ALL_PRODUCTS")}
            </NavLink>
          </div>

          {/* Cart */}
          <div className={`cart ${cartItems.length ? "visible" : "hidden"}`}>
            <div className="cart-inner">
              {/* Cart items */}
              <div
                className={`cart-items-container ${
                  showMobileCart ? "visible" : "hidden"
                }`}
              >
                <div
                  className="cart-header"
                  onClick={() => {
                    this.setState({ showMobileCart: false }, () =>
                      document
                        .querySelector("html")
                        .setAttribute("style", "overflow: visible;")
                    );
                  }}
                ></div>
                <div className="cart-content">
                  <h3
                    className={`cart-heading ${
                      this.state.showMobileCart ? "visible" : "hidden"
                    }`}
                  >
                    {translate(websiteLanguage, "CART_TITLE")}
                    <button
                      className="close-modal-button"
                      onClick={() => {
                        this.setState({ showMobileCart: false }, () =>
                          document
                            .querySelector("html")
                            .setAttribute("style", "overflow: visible;")
                        );
                      }}
                    >
                      <img alt="close" src="./icons/close-icon.svg" />
                    </button>
                  </h3>
                  <div className="cart-row-items">
                    {cartItems.map((item) => (
                      <div className="cart-item-row" key={item._id + item.name}>
                        <div className="cart-items">
                          {item._id !== "customOrder" && item.quantity}
                          {item._id !== "customOrder" ? " " : ""}
                          {item.name}: {item.quantity * item.price}{" "}
                          {translate(websiteLanguage, "CURRENCY_NORWEGIAN")}
                        </div>
                        <div className="cart-buttons">
                          {item._id !== "customOrder" && (
                            <button
                              className="add-cart-item"
                              onClick={() => this.handleAddToCart(item, true)}
                            >
                              <img src="./icons/add.svg" alt="Add item icon" />
                            </button>
                          )}
                          {item._id !== "customOrder" && (
                            <button
                              className="subtract-cart-item"
                              onClick={() =>
                                this.removeItemFromCart(item, false)
                              }
                            >
                              <img
                                src="./icons/subtract.svg"
                                alt="Subtract item icon"
                              />
                            </button>
                          )}
                          <button
                            className="remove-cart-item"
                            onClick={() => this.deleteItemFromCart(item)}
                          >
                            <img
                              src="./icons/remove.svg"
                              alt="Remove item icon"
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  {(websiteLanguage === "no" || websiteLanguage === "en") &&
                    renderFreeShippingText(false, websiteLanguage, cartItems)}
                </div>
              </div>

              <div className="cart-summary">
                <button
                  className="mobile-cart-toggle"
                  onClick={() => {
                    this.setState(
                      {
                        showMobileCart: !this.state.showMobileCart,
                      },
                      () => {
                        if (this.state.showMobileCart) {
                          document
                            .querySelector("html")
                            .setAttribute("style", "overflow: hidden;");
                        } else {
                          document
                            .querySelector("html")
                            .setAttribute("style", "overflow: visible;");
                        }
                      }
                    );
                  }}
                >
                  <img
                    className={`chevron-icon ${
                      this.state.showMobileCart ? "down" : "up"
                    }`}
                    src="./icons/chevron.svg"
                    alt="chevron-icon"
                  ></img>
                  <img
                    className="cart-icon"
                    src="./icons/ruck.svg"
                    alt="cart-icon"
                  />
                  <div
                    className={`cart-badge ${
                      this.state.itemAddedToCart ? "animation" : ""
                    }`}
                  >
                    {itemsInCart(this.state.cartItems)}
                  </div>
                </button>
                <div
                  className="cart-total-price"
                  onClick={() => {
                    this.setState(
                      {
                        showMobileCart: !this.state.showMobileCart,
                      },
                      () => {
                        if (this.state.showMobileCart) {
                          document
                            .querySelector("html")
                            .setAttribute("style", "overflow: hidden;");
                        } else {
                          document
                            .querySelector("html")
                            .setAttribute("style", "overflow: visible;");
                        }
                      }
                    );
                  }}
                >
                  {translate(
                    this.state.websiteLanguage,
                    "CART_SUBTOTAL_AMOUNT"
                  )}{" "}
                  {calculateAmount(cartItems, false)}
                  {",00 "}
                  {translate(this.state.websiteLanguage, "CURRENCY_NORWEGIAN")}
                </div>
                <div className="cart-to-checkout">
                  {cartItems.length > 0 && (
                    <button
                      className="checkout-button"
                      onClick={() => this.props.history.push("/checkout")}
                    >
                      {translate(
                        this.state.websiteLanguage,
                        "CART_TO_CHECKOUT"
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>{product && this.renderProductImagesList(product)}</section>
      </div>
    );
  }
}

export default observer(ProductView);
